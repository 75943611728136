import { useEffect } from 'react'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import "./index.scss"
import { useDispatch, useSelector } from "react-redux";
import SeeAll from "../../../assets/icons/seeAll.svg"
import transactionActions from '../../../redux/transactions/actions'

const { getTransactionsData } = transactionActions;

function TransactionData() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { transactionData } = useSelector((state) => state.transactionReducer)
    const { agentId, permissions } = useSelector((state) => { 
      return {
        agentId: state.authReducer.agentId,
        permissions:state.authReducer.profileData.permissions
      }
    });
    // console.log(agentId , "transaction overview pages ks ") 

  useEffect(() => {
      // debugger
    if (!agentId || agentId=="") {
      dispatch(getTransactionsData());
    }else{
      dispatch(getTransactionsData(0, 10,"","","",  agentId));
    }

  }, [agentId, dispatch]);

  const HandleChange = () => {
    navigate("/transactions");
  };
    


    return (
      <>
        <div>
            <div className="headingTag">
                <h1>Transaction</h1>
                {Array.isArray(permissions)?.length>0&&permissions?.some((item)=>item?.module==="transactions")&&<div onClick={HandleChange} style={{ cursor: 'pointer' }}>See All <img src={SeeAll} alt='see all'></img></div>}
            </div>
          <div className="tableStyle">
            {transactionData.transactions && transactionData.transactions.length > 0 ? transactionData.transactions.slice(0, 8).map((item, key) => (
              <div className="transactionData" key={key}>
                <div>
                  <h1>{item.transactionId}</h1>
                  <h2>{`${moment(item.createDate).format("DD MMMM YYYY")},${moment(new Date(item.createDate)).format("hh:mm:ss A")}`}</h2>
                </div>
                <div className="price">
                  <h3>+₹{item.amount}</h3>
                  <h4>{item.status}</h4>
                </div>
              </div>
            )) : <div className="" style={{ color: 'rgba(0, 0, 0, 0.45)', textAlign: 'center', fontSize: 16 }}>No data</div>}
          </div>
        </div>
      </>
    )
}

export default TransactionData
