import actions from './actions';

const initState = {
  loginToken: null,
  loginLoading: false,
  loginMessage: null,
  loginError: null,
  verifyError: null,
  loading: false,
  profileData: [],
  error: null,
  tokenData: [],
  tokenError: null,
  authMessage: null,
  authError: null,
  changePasswordError: null,
  changePasswordMessage: null,
  totalAgent: [],
  agentId: null,
  forgetPassword:{
    loading:false,
    error:'',
    response:null
  },
  resetPassword:{
    loading:false,
    error:'',
    response:null
  }
};


export default function authReducer(state = initState, action) {
  switch (action.type) {

    case actions.LOGIN:
      return {
        ...state,
        loginLoading: true,
        loginMessage: null,
        loginError: null,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginToken: action.payload.token,
        loginData: action.payload,
        loginMessage: 'Login success',
        loginError: null,
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        loginLoading: false,
        loginMessage: null,
        loginError: action.payload.error,
      };

    case actions.GET_PROFILE:
      return {
        ...state,
        loading: true
      };
    case actions.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action.payload.profileData,
      };
    case actions.GET_PROFILE_FAILED:
      return {
        ...state,
        loginLoading: false,
        loginMessage: null,
        error: action.payload.error,
      };

    case actions.VALIDATE_CODE:
      return {
        ...state,
        loading: true
      };
    case actions.VALIDATE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        validateMessage: action.payload.message
      };
    case actions.VALIDATE_CODE_FAILED:
      return {
        ...state,
        loginLoading: false,
        loginMessage: null,
        error: action.payload.error,
      };
    case actions.VERIFY_CODE:
      return {
        ...state,
        loading: true
      };
    case actions.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyCodeMessage: action.payload.message,
      };
    case actions.VERIFY_CODE_FAILED:
      return {
        ...state,
        loginLoading: false,
        loginMessage: null,
        verifyError: action.payload.error,
      };

    case actions.CHANGE_PASSWORD:
      return {
        ...state,
        buttonLoading: true
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        changePasswordMessage: 'Password Updated Successfully',
      };
    case actions.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        buttonLoading: false,
        changePasswordError: action.payload.error,
      };

    case actions.AUTH_RESET:
      return {
        ...state,
        loginLoading: false,
        loading: false,
        loginMessage: null,
        loginError: null,
        changePasswordError: null,
        changePasswordMessage: null,
        verifyError: null
      };
      case actions.GET_AGENT_DATA:
        return {
          ...state,
          loading: true,
        };
      case actions.GET_AGENT_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          totalAgent: action.payload.totalAgent
        };
      case actions.GET_AGENT_DATA_FAILED:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      case actions.SELECT_AGENT_SUCCESS:
        return {
          ...state,
          agentId: action.payload,
        };
      case actions.SELECT_AGENT_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
    case actions.FORGOT_PASSWORD:
      return {
        ...state,
        forgetPassword:{
          ...state.forgetPassword,
          response:null,
          loading:true,
          error:''
        }
      };

    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPassword:{
          ...state.forgetPassword,
          response:action.payload,
          loading:false,
          error:''
        }
      };

    case actions.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
       forgetPassword:{
        ...state.forgetPassword,
        response:null,
        loading:false,
        error:action.payload
       }
      };
    
    case actions.FORGET_PASSWORD_ERROR_RESET:
      return {
        ...state,
        forgetPassword:{
          ...state.forgetPassword,
          error:'',
          loading:false,
          response:null
        }
      }

    case actions.RESET_PASSWORD:
      return {
        ...state,
       resetPassowrd:{
        ...state.resetPassword,
        response:null,
        loading:true,
        error:''
       }
      };

    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword:{
          ...state.resetPassword,
          response:action.payload,
          loading:false,
          error:''
        }
      };

    case actions.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPassword:{
          ...state.resetPassword,
          response:null,
          loading:false,
          error:action.payload
        }
      };

    default:
      return state;
  }

}
