const actions = {
  GET_TRANSACTIONS_DATA: 'GET_TRANSACTIONS_DATA',
  GET_TRANSACTIONS_DATA_SUCCESS: 'GET_TRANSACTIONS_DATA_SUCCESS',
  GET_TRANSACTIONS_DATA_FAILED: 'GET_TRANSACTIONS_DATA_FAILED',
  GET_CHART_DATA: 'GET_CHART_DATA',
  GET_CHART_DATA_SUCCESS: 'GET_CHART_DATA_SUCCESS',
  GET_CHART_DATA_FAILED: 'GET_CHART_DATA_FAILED',

  getTransactionsData: (offset, limit, search, fromDate, toDate,agentId) => (
    {
    type: actions.GET_TRANSACTIONS_DATA,
    payload: { offset, limit, search, fromDate, toDate,agentId },
  }),
  getTransactionsDataSuccess: (data, totalData) => ({
    type: actions.GET_TRANSACTIONS_DATA_SUCCESS,
    payload: { data, totalData },
  }),
  getTransactionsDataFailed: (error) => ({
    type: actions.GET_TRANSACTIONS_DATA_FAILED,
    payload: { error },
  }),

  getChartData: (agentId) => ({
    type: actions.GET_CHART_DATA,
    payload: {agentId },
  }),
  getChartDataSuccess: (data) => ({
    type: actions.GET_CHART_DATA_SUCCESS,
    payload: { data },
  }),
  getChartDataFailed: (error) => ({
    type: actions.GET_CHART_DATA_FAILED,
    payload: { error },
  }),

};

export default actions;
