import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router';
import plutosLogo from "../../assets/icons/plutosLogo.svg";
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import "./Sidebar.scss"

function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const token = Cookies.get("biller_access");
    const permissionsLocalStorage = localStorage.getItem('module_access');
    const [selectedKey, setSelectedKey] = useState('/');


    const menuItems = [

        {
            label: "Users",
            key: "/user",
            permission: "users"
        },
        {
            label: "Transactions",
            key: "/transactions",
            permission: "transactions"
        },
        // {
        //     label: "Billing Cycle",
        //     key: "/billing-cycle",
        //     permission: "billing_cycle"
        // },
        // {
        //     label: "SIMPL",
        //     key: "/simpl",
        //     permission: "SIMPL"
        // },
        // {
        //     label: "Complaints",
        //     key: "/complaints",
        //     permission: "complaints"
        // },
        // {
        //     label: "Customer Parameters",
        //     key: "/customer-parameters",
        //     permission: "customer_parameters"
        // },
        {
            label: "Settlement & Reconciliation",
            key: "/settlement",
            permission: "settlements"
        },
        {
            label: "Biller Performance",
            key: "/biller-performance",
            permission: "Biller Performance"
        },
        {
            label: "Communication Channel",
            key: "/communication-channel",
            permission: "Communication Channel"
        },
        {
            label: "Mails",
            key: "/mails",
            permission: "mails"
        },
        // {
        //     label: "Repco Integration",
        //     key: "/repco-integration",
        //     permission: "repco integration"
        // },
        // {
        //     label: "Planned Downtime",
        //     key: "/planned",
        //     permission: "planned"
        // }
    ];


    useEffect(() => {
        setSelectedKey(location.pathname);
    }, [location.pathname]);

    if (!token) {
        window.location.href = '/login';
        return null;
    }

    // Decrypt and parse permissions
    const decryptedData = permissionsLocalStorage
        ? CryptoJS.AES.decrypt(permissionsLocalStorage, process.env.REACT_APP_ENCRYPTED_KEY).toString(CryptoJS.enc.Utf8)
        : null;
    const permissionsData = decryptedData ? JSON.parse(decryptedData) : {};
    const isSuperAdmin = permissionsData?.permissions === true;
    const permissions = permissionsData?.permissions || [];

    console.log(permissions , permissionsData , decryptedData , isSuperAdmin , "token ")

    // Filter menu items based on permissions
    const filteredMenuItems = [
        { label: 'Overview', key: '/' },
        ...(isSuperAdmin
            ? menuItems
            : menuItems.filter((item) =>
                permissions.some(
                    (permission) =>
                        permission.module.toLowerCase() === item.permission.toLowerCase() && permission.read
                )
            )),
    ];

    return (
        <div className='AppSidebar'>
            <Menu
                selectedKeys={[selectedKey]}
                defaultSelectedKeys={['/']}
                onClick={(item) => navigate(item.key)}
                items={filteredMenuItems.map(item => ({
                    label: item.label,
                    key: item.key,
                }))}
            />
            <div className='plutos-logo'><img src={plutosLogo} alt='logo' /></div>
        </div>
    );
}

export default Sidebar;