import { combineReducers } from "redux";
import billerReducer from "./GetBillerData/reducer"
import CategoryReducer from "./GetCategoryData/reducer"
import userReducer from "./user/reducer"
import authReducer from "./auth/reducer"
import transactionReducer from "./transactions/reducer"
import communicationReducer from "./communication_channel/reducer"


const rootReducer = combineReducers({
    billerReducer,
    CategoryReducer,
    authReducer,
    userReducer,
    transactionReducer,
    communicationReducer
});
export default rootReducer;