import {
    GET_CATEGORIES_DATA,
    GET_CATEGORIES_DATA_SUCCESS,
    GET_CATEGORIES_DATA_FAILED,
    CREATE_CATEGORY_DATA,
    CREATE_CATEGORY_DATA_SUCCESS,
    CREATE_CATEGORY_DATA_FAILED,
    UPDATE_CATEGORY_DATA,
    UPDATE_CATEGORY_DATA_SUCCESS,
    UPDATE_CATEGORY_DATA_FAILED,
    DELETE_CATEGORY_DATA,
    DELETE_CATEGORY_DATA_SUCCESS,
    DELETE_CATEGORY_DATA_FAILED,
} from './actions';

// Update the initial state and action type constants accordingly
const initState = {
    loading: false,
    error: null,
    message: null,
    categories: [],
    postCategoryData: [],
    updateCategoryData: [],
};

// Update the switch cases and state updates
export default function CategoryReducer(state = initState, action) {
    switch (action.type) {
        case GET_CATEGORIES_DATA:
        case CREATE_CATEGORY_DATA:
        case UPDATE_CATEGORY_DATA:
        case DELETE_CATEGORY_DATA:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_CATEGORIES_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                categories: action.data
            };
        case CREATE_CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                postCategoryData: action.data
            };
        case UPDATE_CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                updateCategoryData: action.data
            };
        case DELETE_CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                categories: state.categories.filter(category => category.id !== action.id)
            };
        case GET_CATEGORIES_DATA_FAILED:
        case CREATE_CATEGORY_DATA_FAILED:
        case UPDATE_CATEGORY_DATA_FAILED:
        case DELETE_CATEGORY_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };
        default:
            return state;
    }
}
