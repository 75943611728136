const actions = {
  AUTH_RESET: 'AUTH_RESET',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILED: 'GET_PROFILE_FAILED',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
  VERIFY_CODE: 'VERIFY_CODE',
  VERIFY_CODE_SUCCESS: 'VERIFY_CODE_SUCCESS',
  VERIFY_CODE_FAILED: 'VERIFY_CODE_FAILED',
  VALIDATE_CODE: 'VALIDATE_CODE',
  VALIDATE_CODE_SUCCESS: 'VALIDATE_CODE_SUCCESS',
  VALIDATE_CODE_FAILED: 'VALIDATE_CODE_FAILED',
  GET_AGENT_DATA: 'GET_AGENT_DATA',
  GET_AGENT_DATA_SUCCESS: 'GET_AGENT_DATA_SUCCESS',
  GET_AGENT_DATA_FAILED: 'GET_AGENT_DATA_FAILED',
  SELECT_AGENT_REQUEST: 'SELECT_AGENT_REQUEST',
  SELECT_AGENT_SUCCESS: 'SELECT_AGENT_SUCCESS',
  SELECT_AGENT_FAILURE: 'SELECT_AGENT_FAILURE',
  FORGOT_PASSWORD:'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS:'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED:'FORGOT_PASSWORD_FAILED',
  FORGET_PASSWORD_ERROR_RESET:'FORGET_PASSWORD_ERROR_RESET',
  RESET_PASSWORD:'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS:'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED:'RESET_PASSWORD_FAILED',


  login: (data) => ({
    type: actions.LOGIN,
    payload: { data },
  }),
  loginSuccess: (loginData) => ({
    type: actions.LOGIN_SUCCESS,
    payload: { loginData },
  }),
  loginFailed: (error) => ({
    type: actions.LOGIN_FAILED,
    payload: { error },
  }),

  validateCode: () => ({
    type: actions.VALIDATE_CODE,

  }),
  validateCodeSuccess: (message) => ({
    type: actions.VALIDATE_CODE_SUCCESS,
    payload: { message },
  }),
  validateCodeFailed: (error) => ({
    type: actions.VALIDATE_CODE_FAILED,
    payload: { error },
  }),
  verifyCode: (data,verify) => ({
    type: actions.VERIFY_CODE,
    payload: { data, verify },
  }),
  verifyCodeSuccess: (message) => ({
    type: actions.VERIFY_CODE_SUCCESS,
    payload: { message },
  }),
  verifyCodeFailed: (error) => ({
    type: actions.VERIFY_CODE_FAILED,
    payload: { error },
  }),

  getProfile: () => ({
    type: actions.GET_PROFILE,
  }),
  getProfileSuccess: (profileData) => ({
    type: actions.GET_PROFILE_SUCCESS,
    payload: { profileData },
  }),
  getProfileFailed: (error) => ({
    type: actions.GET_PROFILE_FAILED,
    payload: { error },
  }),

  changePassword: (data) => ({
    type: actions.CHANGE_PASSWORD,
    payload: { data }
  }),
  changePasswordSuccess: (message) => ({
    type: actions.CHANGE_PASSWORD_SUCCESS,
    payload: { message },
  }),
  changePasswordFailed: (error) => ({
    type: actions.CHANGE_PASSWORD_FAILED,
    payload: { error },
  }),

  authReset: () => ({
    type: actions.AUTH_RESET,
  }),
  getAgentData: ( offset, limit, search) => ({
    type: actions.GET_AGENT_DATA,
    payload:{ offset, limit, search }
  }),
  getAgentDataSuccess: (totalAgent) => ({
    type: actions.GET_AGENT_DATA_SUCCESS,
    payload: { totalAgent },
  }),
  getAgentDataFailed: (error) => ({
    type: actions.GET_AGENT_DATA_FAILED,
    payload: { error },
  }),
  selectAgentRequest: (agentId) => ({
    type: actions.SELECT_AGENT_REQUEST,
    payload: { agentId },
  }),
  selectAgentSuccess: (data) => ({
    type: actions.SELECT_AGENT_SUCCESS,
    payload: data,
  }),
  selectAgentFailure: (error) => ({
    type: actions.SELECT_AGENT_FAILURE,
    payload: error,
  }),
  forgotPassword: (data) => ({
    type: actions.FORGOT_PASSWORD,
    payload: { data }
  }),
  forgotPasswordSuccess: (message) => ({
    type: actions.FORGOT_PASSWORD_SUCCESS,
    payload:  message
  }),
  forgotPasswordFailed: (message) => ({
    type: actions.FORGOT_PASSWORD_FAILED,
    payload:  message 
  }),
  forgotPasswordErrorReset:()=>({
    type:actions.FORGET_PASSWORD_ERROR_RESET
  }),
  resetPassword: (data) => ({
    type: actions.RESET_PASSWORD,
    payload: { data }
  }),
  resetPasswordSuccess: (message) => ({
    type: actions.RESET_PASSWORD_SUCCESS,
    payload:  message
  }),
  resetPasswordFailed: (message) => ({
    type: actions.RESET_PASSWORD_FAILED,
    payload:  message 
  })
};

export default actions;
