import React, { useEffect, useState } from "react";
import "./transaction.scss";
import moment from "moment";
import { DatePicker, Table, Tag } from "antd";
import { CalendarOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import transactionActions from "../../redux/transactions/actions";
import { CSVLink } from "react-csv";
import SearchBar from "../../utils/Searchbar";

const { getTransactionsData } = transactionActions;
function Transactions() {
  const dispatch = useDispatch();
  const { transactionData, totalData, loading } = useSelector(
    (state) => state.transactionReducer
  );
  
  const { agentId } = useSelector((state) => ({
    agentId: state.authReducer.agentId,
  }));
 
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const now = moment();
  const formattedDate = now.format("YYYY-MM-DD");
  const formattedTime = now.format("HH-mm-ss");
  const newFilename = `transactions_data_${formattedDate}_${formattedTime}.csv`;



  //for refund pages
  const navigate = useNavigate();
  const HandleChange = () => {
    navigate("/refund");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      sorter: (a, b) => a?.key-b?.key,
      width: 100, // Fixed width
    },
    {
      title: "DATE",
      dataIndex: "date",
      width: 100, // Fixed width
    },
    {
      title: "TRANSACTION ID",
      dataIndex: "transaction_id",
      sorter: (a, b) => a.transaction_id.localeCompare(b.transaction_id),
      width: 100, // Fixed width
    },
    {
      title: "PAYMENT MODE",
      dataIndex: "payment_mode",
      sorter: (a, b) => a.payment_mode.localeCompare(b.payment_mode),
      width: 100, // Fixed width
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: 100, // Fixed width
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.localeCompare(b.amount),
      width: 100, // Fixed width
    },

    {
      title: "STATUS",
      dataIndex: "status",
      width: 100, // Fixed width
    },
  ];
  
  var data =
    transactionData?.transactions &&
    transactionData?.transactions.length > 0 &&
    transactionData?.transactions.map((items, index) => {
      return {
        key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        transaction_id: items?.transactionId,
        email: items?.email,
        payment_mode: items?.paymentMode,
        amount: `₹${items?.amount}`,
        date: (
          <>
            <p className="m-0 p-0">
              {moment(items.createDate).format("DD MMMM YYYY")}
            </p>
            <p className="m-0 p-0">
              {moment(new Date(items.createDate)).format("hh:mm:ss A")}
            </p>
          </>
        ),
        status: (
          <Tag
            color={
              ["Success", "Successful", "success", "Successfully"].includes(items.status)
                ? "green"
                : items.status === "Pending"
                ? "yellow"
                : "red"
            }
            className="draft"
          >
            {["Success", "Successful", "success", "Successfully"].includes(items.status)
              ? "Success"
              : items.status === "Pending"
              ? "Pending"
              : items.status}
          </Tag>
        ),
      };
    });

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   const value = e.target.value;
  //   setSearch(value);

  //   if (value.length > 1) {
  //     dispatch(getTransactionsData(0, 10, value));
  //   }
  //   if (value.length < 1) {
  //     dispatch(getTransactionsData(0, 10));
  //   }
  // };
  
  let timeoutId;
  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if(transactionData?.transactions.length >= 0){
        dispatch(getTransactionsData(0, 10, value,"","",agentId)); 
      }      
    }, 1000);
  };

  const handlePageChange = async (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setTableParams({ current, pageSize });

    if (fromDate && toDate) {
      dispatch(
        getTransactionsData(
          current,
          pageSize,
          "",
          fromDate?.format("YYYY-MM-DD"),
          toDate?.format("YYYY-MM-DD"),
          agentId
        )
      );
    } else {
      dispatch(getTransactionsData(current, pageSize,"","","",agentId));
    }
  };


  // console.log(agentId , "agentId")
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const fromSendDate = fromDate?.format("YYYY-MM-DD");
    const toDate = date?.format("YYYY-MM-DD");
    if (fromSendDate && toDate) {
      dispatch(getTransactionsData(1, 10, "", fromSendDate, toDate,agentId));
    }else{
      dispatch(getTransactionsData(1, 10,"","","",agentId));
    }
  };

  const disabledToDate = (current) => {
    return (
      (fromDate &&
        current &&
        (current < fromDate.startOf("day") ||
          current > moment().startOf("day"))) ||
      (current && current > moment().startOf("day"))
    );
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const exportData =
    transactionData?.transactions &&
    transactionData?.transactions.length > 0 &&
    transactionData?.transactions?.map((items, index) => {
      return {
        transaction_id: items?.transactionId,
        email: items?.email,
        payment_mode: items?.paymentMode,
        amount: `₹${items?.amount}`,
        Status: items?.status,
      };
    });
   
    useEffect(() => {
      if (!agentId || agentId=="") {
        dispatch(getTransactionsData());
      }else{
        dispatch(getTransactionsData(0, 10,"","","", agentId));
      }

    }, [agentId, dispatch]);


  return (
    <div className="mainContain">
      <h1 className="transaction">Transactions</h1>
      <div className="d-flex gap-3" style={{ cursor: "pointer" }}>
        {/* <p className="suntag">Transaction</p>
        <p onClick={HandleChange} className="suntaginactive">
          Refund
        </p> */}
      </div>
      <div className="datesearchFi">
        <div className="">
             <SearchBar onChange={handleSearch}  />
        </div>
        <div className="inputfatef">
          <div>
            <DatePicker
              value={fromDate}
              style={{ width: "100%", border: "none" }}
              onChange={handleFromDateChange}
              disabledDate={disabledDate}
              format="DD-MM-YYYY"
              placeholder="From (DD-MM-YYYY)" // Placeholder added here
              suffixIcon={
                <CalendarOutlined style={{ color: "#d9d3d3" }} />
              }
            />
          </div>
          <div>
            <DatePicker
              value={toDate}
              style={{ width: "100%", border: "none" }}
              onChange={handleToDateChange}
              disabledDate={disabledToDate}
              format="DD-MM-YYYY"
              placeholder="To(DD-MM-YYYY)" // Placeholder added here
              suffixIcon={
                <CalendarOutlined style={{ color: "#d9d3d3" }} />
              }
            />
          </div>
        </div>
      </div>
      <div className="cardClass">
        <div className="cardpayment">
          <div>Total Payment</div>
          <span>
            ₹ {transactionData?.totalAmount ? transactionData?.totalAmount?.toFixed(1) : 0.0}
          </span>
        </div>
        <div className="cardpayment">
          <div>Total Transactions</div>
          <span>{totalData ? totalData : 0}</span>
        </div>
        <div className="exportBtnDiv">
        {data?.length > 0?
          <CSVLink
            filename={newFilename}
            data={exportData ? exportData : []}
            className="btn exportBtn"
          >
            <DownloadOutlined />
            Export
          </CSVLink> : <div className="btn not-allowed"><DownloadOutlined/><span>Export</span></div>}
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          scroll={{ x: 600 }}
          pagination={{
            total: totalData,
            current: tableParams.current,
            pageSize: tableParams.pageSize,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ["10","20", "50"],
          }}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Transactions;
