import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getDataFromBOU } from '../../Services/ApiMethodHelper';
const baseURLBOU = process.env.REACT_APP_API_BASE_URL;


function* getTransactionsDataResponse({ payload: { offset, limit, search, fromDate, toDate,agentId } }) {
  try {

    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/transactions?page=${offset ? offset : 1}&limit=${limit ? limit : 10}${search ? `&search=${search} ` : ''}${fromDate ? `&fromDate=${fromDate} ` : ''}${toDate ? `&toDate=${toDate} ` : ''}${agentId ? `&billerId=${agentId}` : ""}`);
    if (response.status === 200) {

      yield put(actions.getTransactionsDataSuccess(response.data, response.data.total));
    } else {
      yield put(actions.getTransactionsDataFailed(response.message));
    }
  } catch (error) {
    yield put(actions.getTransactionsDataFailed(error?.response?.data?.message));
  }
}
function* getChartDataResponse({payload:{agentId}}) {
  try {

    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/graph?${agentId ? `billerId=${agentId}` : ""}`);
      yield put(actions.getChartDataSuccess(response.data));
  } catch (error) {
    yield put(actions.getChartDataFailed(error?.response?.data?.message));
  }
}



export default function* rootSaga() {
  yield all([takeEvery(actions.GET_TRANSACTIONS_DATA, getTransactionsDataResponse)]);
  yield all([takeEvery(actions.GET_CHART_DATA, getChartDataResponse)]);
}

