import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getDataFromBOU } from '../../Services/ApiMethodHelper';
const baseURLBOU = process.env.REACT_APP_API_BASE_URL;

function* getBillerDataResponse({ payload: { offset, limit, search, fromDate, toDate } }) {
  try {
    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/billers?page=${offset ? offset : 1}&limit=${limit ? limit : 10}${search ? `&search=${search}` : ''}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}`);
    if (response.status === 200) {
      yield put(actions.getBillerDataSuccess(response?.data?.data, response?.data?.data?.length));
    } else {
      yield put(actions.getBillerDataFailed(response?.message));
    }
  } catch (error) {
    yield put(actions.getBillerDataFailed(error));
  }
}

function* getBillerTransactionHistoryResponse({ payload: { billerId , offset, limit, search, fromDate, toDate } }) {
  try {
    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/transactions?billerId=${billerId}&page=${offset ? offset : 1}&limit=${limit ? limit : 10}${search ? `&search=${search}` : ''}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}`);
    if (response.status === 200) {
      yield put(actions.getBillerTransactionHistorySuccess(response.data, response.data.total));
    } else {
      yield put(actions.getBillerTransactionHistoryFailed(response.message));
    }
  } catch (error) {
    yield put(actions.getBillerTransactionHistoryFailed(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_BILLER_DATA, getBillerDataResponse)]);
  yield all([takeEvery(actions.GET_BILLER_TRANSACTION_HISTORY, getBillerTransactionHistoryResponse)]);
}
