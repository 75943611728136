import React, { useEffect, useRef, useState } from "react";
import "./LoginPage.scss";
import { CopyOutlined } from "@ant-design/icons";
import authActions from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

const { verifyCode, authReset } = authActions;

const Verify = ({ loginData }) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [nextBtn, setNextBtn] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const [step, setStep] = useState(1);
  const [wordOtp, setWordOtp] = useState("");
  const [fadeMessage, setFadeMessage] = useState("");
  const inputsRef = useRef([]);
  const { verifyError, loading } = useSelector((state) => state.authReducer);

  useEffect(() => {
    inputsRef.current[0]?.focus();
  }, []);

  useEffect(() => {
    if (verifyError !== null && verifyError !== undefined) {
      errorHandle(verifyError);
    }
    // eslint-disable-next-line
  }, [verifyError]);

  const errorHandle = (text) => {
    const hide = messageApi.open({
      type: "error",
      content: text,
    });
    setTimeout(hide, 5000);
    dispatch(authReset());
  };


  const handlePaste = (event) => {
    event.preventDefault();
    const pastedValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    const otpLength = otp.length;

    for (let i = 0; i < otpLength; i++) {
      if (i < pastedValue.length) {
        otp[i] = pastedValue[i];
        if (inputsRef.current[i]) {
          inputsRef.current[i].value = pastedValue[i];
          inputsRef.current[i].removeAttribute("disabled");
        }
      } else {
        otp[i] = "";
        if (inputsRef.current[i]) {
          inputsRef.current[i].value = "";
        }
      }

      inputsRef.current[i].focus();
    }

    setOtp([...otp]);
    
    // Check if button should be enabled
    const isOtpFilled = otp.every((val) => val !== "");
    setIsButtonDisabled(!isOtpFilled);
  };

  /*
  This function prevents the user from typing alphabet keys (A-Z, a-z) into an input field, 
  while allowing other keys, including numbers, the 'V' key ( likely for paste functionality), 
  numpad keys, and function keys.
  */
  const handleKeyDown = (event) => {
    const { key, code, keyCode } = event;
    const isAlphabeticKey = keyCode >= 65 && keyCode <= 90;
    const isVKey = key.toLowerCase() === 'v';
    const isNumpadKey = code.startsWith('Numpad');
    const isFunctionKey = code.startsWith('F');
    if (isAlphabeticKey && !isVKey && !isNumpadKey && !isFunctionKey) {
      event.preventDefault();
    }
  };

  const handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return false;

    otp[index] = value[1] ? value[1] : value;       
    const newOtp = otp.map((val) => (val === undefined ? "" : val));
    setOtp(newOtp);

    // Focus next input
    if (element.nextSibling && value !== "") {
      element.nextSibling.removeAttribute("disabled");
      element.nextSibling.focus();
    }

    // Handle backspace
    if (value === "" && element.previousSibling) {
      element.previousSibling.focus();
    }

    // Check if button should be enabled
    const isOtpFilled = otp.every((val) => val !== "");
    setIsButtonDisabled(!isOtpFilled);
  };

  const handleBackspace = (event, index) => {
    if (event.key === "Backspace" && otp[index] === "") {
      otp[index - 1] = "";
      setOtp([...otp]);
      if (inputsRef.current[index - 1]) {
        inputsRef.current[index - 1].focus();
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(true);
        setNextBtn(false);
        setTimeout(() => setCopySuccess(false), 3000); // Hide the message after 3 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const formatSecretCode = (code) => {
    if (code.length > 10) {
      return `${code.slice(0, 12)}${"*".repeat(code.length - 12)}`;
    }
    return code;
  };

  const handleNextClick = () => {
    setStep(2);
  };

  const handleOtpChange = (e) => {
    setWordOtp(e.target.value);
  };

  const handleOtpSubmit = () => {
    if (wordOtp === loginData.secret.slice(-4)) {
      setFadeMessage(
        "Code verified successfully! Proceeding to the next step."
      );
      setTimeout(() => {
        setStep(3);
        setFadeMessage("");
      }, 2000);
    } else {
      setFadeMessage("Incorrect code. Please try again.");
      setTimeout(() => setFadeMessage(""), 2000);
    }
  };

  const handleCodeVerify = (e) => {
    e.preventDefault();
    if (otp.join("").length !== 6) {
        errorHandle("Invalid TOTP. Please enter a 6-digit code.");
        return;
      }
    dispatch(
      verifyCode(
        {
          code: otp.join(""),
        },
        true
      )
    );
  };
  const handleCodeNewVerify = (e) => {
    e.preventDefault();
    if (otp.join("").length !== 6) {
        errorHandle("Invalid TOTP. Please enter a 6-digit code.");
        return;
      }
    
    dispatch(
      verifyCode(
        {
          code: otp.join(""),
        },
        false
      )
    );
  };

  const handleBackClick = () => {
    setStep(step - 1);
  };
  return (
    <div className="col-12 col-md-6 col-lg-4 main-otp">
      <div
        className="card bg-white mb-5 mt-5 border-0"
        style={{ boxShadow: "0 12px 15px rgba(0, 0, 0, 0.02)" }}
      >
        <div className="card-body p-5 text-center d-flex justify-content-center">
          {contextHolder}

          {step === 1 && loginData && loginData.QRCode && (
            <div>
              <h4 style={{ textTransform: "capitalize" }}>
                Setup Two Factor Authentication
              </h4>

              <p style={{ fontSize: "12px", color: "green" }}>
                Please copy the source code before proceeding to the next step.
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Secret Code - </span>{" "}
                {`${formatSecretCode(loginData.secret)}`}
                <CopyOutlined
                  onClick={() => copyToClipboard(loginData.secret)}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
                <br />
                {copySuccess ? (
                  <span style={{ color: "green", minHeight: "15px" }}>
                    Code copied!
                  </span>
                ) : (
                  <span style={{ minHeight: "15px" }}> </span>
                )}
              </p>
              {loginData && loginData.QRCode && (
                <div>
                  <img src={loginData?.QRCode} alt="qr code" />
                </div>
              )}
              <button
                className="custom-btn custom-btn--primary"
                disabled={nextBtn}
                style={{
                  cursor: nextBtn ? "not-allowed" : "pointer",
                  backgroundColor: nextBtn ? "#acb7fd" : "#4e65fc",
                }}
                onClick={handleNextClick}
              >
                Next
              </button>
              {loginData && loginData.QRCode && (
                <p
                  style={{
                    textAlign: "justify",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Note :-{" "}
                  </span>
                  {loginData.message}
                </p>
              )}
            </div>
          )}
          {step === 2 && (
            <div style={{padding:'20px'}}>
              <h4 style={{ textTransform: "capitalize" }}>
                Verify Your Secret Code
              </h4>

              <p style={{minWidth:'250px'}}>Please enter the last 4 digits of the secret code:</p>

              <input
                type="text"
                value={wordOtp}
                onChange={handleOtpChange}
                maxLength="4"
                placeholder="Enter last 4 digits"
                className="custom-form__field"
              />
              <div style={{ display: 'flex', minWidth: '250px', justifyContent: 'space-between' }}>
                <button
                  style={{ width: '6rem', backgroundColor: "#e42a2a", marginRight: "8px" }}
                  className="custom-btn custom-btn--primary"
                  onClick={handleBackClick}
                > 
                  Back
                </button>
                <button
                  style={{ width: '6rem' }}
                  className="custom-btn custom-btn--primary"
                  onClick={handleOtpSubmit}
                >
                  Submit
                </button>
              </div>
              {fadeMessage && (
                <p
                  style={{
                    color: fadeMessage.includes("successfully")
                      ? "green"
                      : "red",
                  }}
                >
                  {fadeMessage}
                </p>
              )}
            </div>
          )}
          {step === 3 && (
            <>
              <form onSubmit={handleCodeVerify}>
                <div class="title-auth">
                  <h5>TOTP AUTHENTICATION</h5>
                  <p>Enter the 6 digit OTP from authenticator app.</p>
                </div>
                <div className="otp-field mb-4">
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      type="number"
                      className="otp-input"
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onKeyUp={(e) => handleBackspace(e, index)}
                      ref={(el) => (inputsRef.current[index] = el)}
                      disabled={index !== 0}
                      onPaste={index === 0 ? handlePaste : null}
                      onKeyDown={handleKeyDown}
                      onFocus={(e) => e.target.select()}
                    />
                  ))}
                </div>
                <button
                  className="custom-btn custom-btn--primary"
                  loading={loading}
                  disabled={isButtonDisabled}
                  style={{
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  Verify
                </button>
              </form>
            </>
          )}
  
          {loginData.token && (
          <>
            <form onSubmit={handleCodeNewVerify}>
              <div className="title-auth">
                <h5>TOTP AUTHENTICATION</h5>
                <p>Enter the 6 digit OTP from authenticator app.</p>
              </div>
              <div className="otp-field mb-4">
                {otp.map((data, index) => (
                  <input
                    key={index}
                    type="number"
                    className="otp-input"
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyUp={(e) => handleBackspace(e, index)}
                    ref={(el) => (inputsRef.current[index] = el)}
                    disabled={index !== 0}
                    onPaste={index === 0 ? handlePaste : null}
                    onKeyDown={handleKeyDown}
                    onFocus={(e) => e.target.select()}
                  />
                ))}
              </div>

              <button
                className="btn btn-primary mb-3"
                disabled={isButtonDisabled}
                style={{
                  cursor: isButtonDisabled ? "not-allowed" : "pointer",
                }}
              >
                Verify
              </button>
            </form>
          </>
          )}

        </div>
      </div>
    </div>
  );
};

export default Verify;