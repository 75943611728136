// Change action type constants from "Biller" to "Category"
const GET_CATEGORIES_DATA = "GET_CATEGORIES_DATA";
const GET_CATEGORIES_DATA_SUCCESS = "GET_CATEGORIES_DATA_SUCCESS";
const GET_CATEGORIES_DATA_FAILED = "GET_CATEGORIES_DATA_FAILED";

const CREATE_CATEGORY_DATA = "CREATE_CATEGORY_DATA";
const CREATE_CATEGORY_DATA_SUCCESS = "CREATE_CATEGORY_DATA_SUCCESS";
const CREATE_CATEGORY_DATA_FAILED = "CREATE_CATEGORY_DATA_FAILED";

const UPDATE_CATEGORY_DATA = "UPDATE_CATEGORY_DATA";
const UPDATE_CATEGORY_DATA_SUCCESS = "UPDATE_CATEGORY_DATA_SUCCESS";
const UPDATE_CATEGORY_DATA_FAILED = "UPDATE_CATEGORY_DATA_FAILED";

const DELETE_CATEGORY_DATA = "DELETE_CATEGORY_DATA";
const DELETE_CATEGORY_DATA_SUCCESS = "DELETE_CATEGORY_DATA_SUCCESS";
const DELETE_CATEGORY_DATA_FAILED = "DELETE_CATEGORY_DATA_FAILED";

// Update action creators accordingly
const getCategories = (payload) => ({
    type: GET_CATEGORIES_DATA,
    payload
});

const getCategoriesSuccess = (data) => ({
    type: GET_CATEGORIES_DATA_SUCCESS,
    data
});

const getCategoriesFailed = (error) => ({
    type: GET_CATEGORIES_DATA_FAILED,
    error
});

const createCategory = (data) => ({
    type: CREATE_CATEGORY_DATA,
    data
});

const createCategorySuccess = (data) => ({
    type: CREATE_CATEGORY_DATA_SUCCESS,
    data
});

const createCategoryFailed = (error) => ({
    type: CREATE_CATEGORY_DATA_FAILED,
    error
});

const updateCategory = (data) => ({
    type: UPDATE_CATEGORY_DATA,
    data
});

const updateCategorySuccess = (data) => ({
    type: UPDATE_CATEGORY_DATA_SUCCESS,
    data
});

const updateCategoryFailed = (error) => ({
    type: UPDATE_CATEGORY_DATA_FAILED,
    error
});

const deleteCategory = (id) => ({
    type: DELETE_CATEGORY_DATA,
    id
});

const deleteCategorySuccess = (id) => ({
    type: DELETE_CATEGORY_DATA_SUCCESS,
    id
});

const deleteCategoryFailed = (error) => ({
    type: DELETE_CATEGORY_DATA_FAILED,
    error
});

// Export the updated action creators
export {
    GET_CATEGORIES_DATA,
    GET_CATEGORIES_DATA_SUCCESS,
    GET_CATEGORIES_DATA_FAILED,
    CREATE_CATEGORY_DATA,
    CREATE_CATEGORY_DATA_SUCCESS,
    CREATE_CATEGORY_DATA_FAILED,
    UPDATE_CATEGORY_DATA,
    UPDATE_CATEGORY_DATA_SUCCESS,
    UPDATE_CATEGORY_DATA_FAILED,
    DELETE_CATEGORY_DATA,
    DELETE_CATEGORY_DATA_SUCCESS,
    DELETE_CATEGORY_DATA_FAILED,
    getCategories,
    getCategoriesSuccess,
    getCategoriesFailed,
    createCategory,
    createCategorySuccess,
    createCategoryFailed,
    updateCategory,
    updateCategorySuccess,
    updateCategoryFailed,
    deleteCategory,
    deleteCategorySuccess,
    deleteCategoryFailed,
};
