import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import './index.scss'
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import chartAction from "../../../redux/transactions/actions";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const { getChartData } = chartAction;

const BillingCycleGraph = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { chartData } = useSelector((state) => state.transactionReducer)
  const { agentId } = useSelector((state) => state.authReducer)

  const [selectedOption, setSelectedOption] = useState("yearly");
  const [series, setSeries] = useState([
    {
      name: "Amount",
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      colors: ["#42BE65"],
    },
    grid: {
      row: {
        colors: ["transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
    },
    markers: {
      size: 8,
      colors: ["#42BE65"],
      strokeWidth: 2,
      stroke: "#ffffff",
    },
  });
  const navigateToTransactionPage = () => {
    navigate("/transactions");
  };

  
  useEffect(() => {
    if (!agentId || agentId=="") {
      dispatch(getChartData());
    }else{
        dispatch(getChartData(agentId));
    }

  }, [agentId, dispatch]);

  const handleSelectChange = (event) => {
    setSelectedOption(event);
  };


  useEffect(() => {
    if (chartData) {
      if (selectedOption === "monthly" && chartData?.sixMonth) {
        setSeries([
          {
            name: "Amount",
            data: chartData?.sixMonth?.data,
          },
        ]);

        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions?.xaxis,
            categories: chartData?.sixMonth?.time,
          },
        }));
      } else if (selectedOption === "yearly" && chartData?.sixYear) {
        const yearLabels = chartData?.sixYear?.time?.map((year) =>
          year?.toString()
        );
        setSeries([
          {
            name: "Amount",
            data: chartData?.sixYear?.data,
          },
        ]);

        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions?.xaxis,
            categories: yearLabels,
          },
        }));
      }
    }
  }, [chartData, selectedOption]);

  return (
    <>
    <div className='headingGrpah'>
    <h4>Billing Cycle</h4>
    {chartData?.sixYear && chartData?.sixMonth && (
          <div>
            <Select
              value={selectedOption}
              onChange={handleSelectChange}
              style={{ width: 120 }}
            >
              <Option value="monthly">Monthly</Option>
              <Option value="yearly">Yearly</Option>
            </Select>
          </div>
        )}
    {/* <h5>Quarterly</h5> */}
    </div>
        
      <div className='cssGraph'>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="line" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
    </>
   
  );
}

export default BillingCycleGraph;
