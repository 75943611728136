import React, { useState } from 'react';
import { Modal, Form, Row, Col, Select, Input, Button, Checkbox, Collapse } from 'antd';
import PropTypes from 'prop-types';

const { Panel } = Collapse;

const UserModal = ({
    fromTitle,
    open,
    buttonLoading,
    confirmLoading,
    handleCancel,
    handleSubmit,
    editFlag,
    formButton,
    UserForm,
    selectedModules,
    handleModuleChangenew,
    roles,
    modules,
}) => {
    const [permissions, setPermissions] = useState({});
    // console.log(UserForm , "kamnao ")

    // Handle individual module permissions
    const handlePermissionChange = (module, selectedPermissions) => {
        if (selectedPermissions.length > 0 && !selectedPermissions.includes('read')) {
            selectedPermissions.push('read');
        }
        setPermissions((prev) => ({
            ...prev,
            [module]: selectedPermissions,
        }));
        UserForm.setFieldsValue({ [`${module}_permissions`]: selectedPermissions });
    };

    return (
        <Modal
            title={fromTitle}
            open={open}
            onCancel={handleCancel}
            width={700}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    CANCEL
                </Button>,
                <Button
                    type="primary"
                    key="submit"
                    style={{ backgroundColor: '#073763', borderColor: '#073763', color: 'white' }}
                    form="userForm"
                    loading={buttonLoading}
                    htmlType="submit"
                >
                    {formButton}
                </Button>,
            ]}
        >
            <Form
                id="userForm"
                form={UserForm}
                layout="vertical"
                onFinish={handleSubmit}
                autoComplete="off"
                initialValues={{
                    roleId: UserForm.getFieldValue('roleId') || undefined,
                }}
            >
                <Row gutter={{ xs: 24, sm: 12, md: 24, lg: 24 }}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter user name!',
                                },
                            ]}
                        >
                            <Input disabled={editFlag} placeholder="Enter user name" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            label="Roles"
                            name="role"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select user role!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select user role"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {roles.map((role) => (
                                    <Select.Option key={role.roleId} value={role.role}>
                                        {role.role}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter user email!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please input a valid email!',
                                },
                            ]}
                        >
                            <Input disabled={editFlag} placeholder="Enter user email" />
                        </Form.Item>
                    </Col>

                    {!editFlag && (
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                    {
                                        min: 6,
                                        message: 'Password must be at least 6 characters!',
                                    },
                                ]}
                            >
                                <Input.Password autoComplete="new-password" placeholder="Enter password" />
                            </Form.Item>
                        </Col>
                    )}

                    <Col xs={24}>
                        <Form.Item
                            label="Permissions"
                            name="permissions"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select permissions!',
                                },
                            ]}
                        >
                            <Checkbox.Group>
                                <Row gutter={[16, 16]}>
                                    {modules.map((module) => (
                                        <Col key={module.moduleName} span={12}>
                                            <Checkbox
                                                value={module.moduleName}
                                                onChange={() => handleModuleChangenew(module.moduleName)}
                                            >
                                                {module.moduleName}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                        <Collapse defaultActiveKey={selectedModules}>
                            {selectedModules.map((module) => (
                                <Panel header={module} key={module}>
                                    <Form.Item
                                        name={`${module}_permissions`}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select at least one option!',
                                            },
                                        ]}
                                    >
                                        <Checkbox.Group
                                            value={permissions[module] || ['read']}
                                            onChange={(selected) => handlePermissionChange(module, selected)}
                                        >
                                            <Checkbox value="create">Allow Create</Checkbox>
                                            <Checkbox value="read" disabled>
                                                Allow Read
                                            </Checkbox>
                                            <Checkbox value="update">Allow Update</Checkbox>
                                            <Checkbox value="delete">Allow Delete</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Panel>
                            ))}
                        </Collapse>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

UserModal.propTypes = {
    fromTitle: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    confirmLoading: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    editFlag: PropTypes.bool.isRequired,
    formButton: PropTypes.string.isRequired,
    UserForm: PropTypes.object.isRequired,
    selectedModules: PropTypes.array.isRequired,
    handleModuleChangenew: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    modules: PropTypes.array.isRequired,
};

export default UserModal;
