import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker, Table, Tag, Button } from "antd";
import { CalendarOutlined, DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import complaintActions from "../../redux/complaints/actions";
import { CSVLink } from "react-csv";
import { generateFilename } from "../../utils/exportFileName";
import SearchBar from "../../utils/Searchbar";
import complaintsData from "./Complaint.json";

const { getComplaintsData } = complaintActions;

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    sorter: (a, b) => a?.key - b?.key,
    width: 100, // Fixed width
  },
  {
    title: "DATE",
    dataIndex: "date",
    width: 100, // Fixed width
  },
  {
    title: "COMPLAINT ID",
    dataIndex: "complaint_id",
    sorter: (a, b) => a.complaint_id?.localeCompare(b.complaint_id),
    width: 100, // Fixed width
  },
  {
    title: "COMPLAINT TYPE",
    dataIndex: "complaint_type",
    sorter: (a, b) => a.complaint_type?.localeCompare(b?.complaint_id),
    width: 100, // Fixed width
  },
  {
    title: "OPEN COMPLAINT",
    dataIndex: "open_complaint",
    width: 100, // Fixed width
  },
  {
    title: "ASSIGNED",
    dataIndex: "assigned",
    width: 100, // Fixed width
  },

  {
    title: "STATUS",
    dataIndex: "status",
    width: 100, // Fixed width
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    width: 100, // Fixed width
  },
];
function Complaints() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const { complaintsData, totalData, loading } = useSelector(
    (state) => state.complaintReducer
  );
  const { agentId } = useSelector((state) => ({
    agentId: state.authReducer.agentId,
  }));

 

  var data =
    complaintsData.complaints &&
    complaintsData.complaints.length > 0 &&
    complaintsData.complaints.map((items, index) => {
      return {
        key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        complaint_id:
          items?.response?.txnStatusComplainResp[0]?.complaintId || "",
        complaint_type: items?.request?.complaintType,
        open_complaint: (
          <Tag
            color={
              items?.response?.txnStatusComplainResp[0]?.openComplaint === "N"
                ? "red"
                : "green"
            }
          >
            {items?.response?.txnStatusComplainResp[0]?.openComplaint || ""}
          </Tag>
        ),
        assigned: `${
          items?.response?.txnStatusComplainResp[0]?.assigned || ""
        }`,
        remarks: items?.response?.txnStatusComplainResp[0]?.remarks || "",
        date: (
          <>
            <p className="m-0 p-0">
              {moment(items.createTime).format("DD MMMM YYYY")}
            </p>
          </>
        ),
        status: (
          <Tag
            color={
              items?.response?.txnStatusComplainResp[0]?.responseReason ===
              "SUCCESS"
                ? "green"
                : items?.response?.txnStatusComplainResp[0]?.responseReason ===
                  "Pending"
                ? "yellow"
                : "red"
            }
            className="draft"
          >
            {items?.response?.txnStatusComplainResp[0]?.responseReason}
          </Tag>
        ),
      };
    });

  // const handleFilterChange = (e) => {
  //     e.preventDefault()
  //     const value = e.target.value;
  //     setSearch(value);

  //     if (value.length > 1) {
  //         dispatch(getComplaintsData(0, 10, value));
  //     }
  //     if (value.length < 1) {
  //         dispatch(getComplaintsData(0, 10));
  //     }
  // };

  let timeoutId;
  const handleFilterChange = (e) => {
    console.log(e.target.value,complaintsData?.legth);
    e.preventDefault();
    const value = e.target.value;
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear the previous timeout if it exists
    }

    timeoutId = setTimeout(() => {
      if(complaintsData?.complaints?.length >= 0){
      dispatch(getComplaintsData(0, 10, value));
      }
    }, 1000);
  };

  const handlePageChange = async (pagination, sofilters, rter) => {
    const { current, pageSize } = pagination;
    setTableParams({ current, pageSize });

    if (fromDate && toDate) {
      dispatch(
        getComplaintsData(
          current,
          pageSize,
          "",
          fromDate?.format("YYYY-MM-DD"),
          toDate?.format("YYYY-MM-DD")
        )
      );
    } else {
      dispatch(getComplaintsData(current, pageSize));
    }
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const fromSendDate = fromDate?.format("YYYY-MM-DD");
    const toDate = date?.format("YYYY-MM-DD");
    if (fromSendDate && toDate) {
      dispatch(getComplaintsData(1, 10, "", fromSendDate, toDate));
    } else {
      dispatch(getComplaintsData(1, 10));
    }
  };

  const disabledToDate = (current) => {
    return (
      (fromDate &&
        current &&
        (current < fromDate.startOf("day") ||
          current > moment().startOf("day"))) ||
      (current && current > moment().startOf("day"))
    );
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const exportData =
    complaintsData.complaints &&
    complaintsData.complaints.length > 0 &&
    complaintsData.complaints.map((items, index) => {
      return {
       key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        complaint_id:
          items?.response?.txnStatusComplainResp[0]?.complaintId || "",
        complaint_type: items?.request?.complaintType,
        assigned:items?.response?.txnStatusComplainResp[0]?.assigned || "",
        remarks: items?.response?.txnStatusComplainResp[0]?.remarks || "",
        date: moment(items.createTime).format("DD MMMM YYYY"),
        status: items?.response?.txnStatusComplainResp[0]?.responseReason,
      }
    });
  console.log("agentId",agentId);
    useEffect(() => {
      if (!agentId || agentId=="") {
        dispatch(getComplaintsData());
      }else{
        dispatch(getComplaintsData(0, 10,"","","",agentId));
      }
    }, [agentId, dispatch]);

  return (
    <div className="mainContain">
      <h1 className="transaction">Complaints</h1>

      <div className="datesearchFi">
        <div className="">
          <SearchBar onChange={handleFilterChange} />
        </div>
        <div className="inputfatef">
          <div>
            {/* <span>From (DD-MM-YYYY)</span> */}
            <DatePicker
              value={fromDate}
              style={{ width: "100%", border: "none" }}
              onChange={handleFromDateChange}
              disabledDate={disabledDate}
              format="DD-MM-YYYY"
              placeholder="From (DD-MM-YYYY)" // Placeholder added here
              suffixIcon={
                <CalendarOutlined style={{ color: "rgba(22, 22, 22, 0.25)" }} />
              }
            />
          </div>
          <div>
            {/* <span>To (DD-MM-YYYY)</span> */}
            <DatePicker
              value={toDate}
              style={{ width: "100%", border: "none" }}
              onChange={handleToDateChange}
              disabledDate={disabledToDate}
              format="DD-MM-YYYY"
              placeholder="To (DD-MM-YYYY)" // Placeholder added here
              suffixIcon={
                <CalendarOutlined style={{ color: "rgba(22, 22, 22, 0.25)" }} />
              }
            />
          </div>
        </div>
      </div>
      {data?.length > 0 ? (
        <div className="exportBtnDiv mt-3">
          <CSVLink
            filename={generateFilename("complaints_data_")}
            data={exportData ? exportData : []}
            className="btn exportBtn"
          >
            <DownloadOutlined />
            Export
          </CSVLink>
        </div>
      ) : (
        ""
      )}

      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          scroll={{ x: 600 }}
          pagination={{
            total: totalData,
            current: tableParams.current,
            pageSize: tableParams.pageSize,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ["10", "20", "50"],
          }}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Complaints;
