import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Table, Modal , Tag } from "antd";
import { DatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import SearchBar from "../../../utils/Searchbar";
import TransactionActions from "../../../redux/GetBillerData/actions";
import moment from "moment";

const { getBillerTransactionHistory } = TransactionActions;

const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      sorter: (a, b) => a?.key-b?.key,
      width: 100,
    },
    {
      title: "DATE",
      dataIndex: "date",
      width: 100, // Fixed width
    },
    {
      title: "TRANSACTION ID",
      dataIndex: "transaction_id",
      // sorter: (a, b) => a.transaction_id.localeCompare(b.transaction_id),
      width: 100, // Fixed width
    },
    {
      title: "PAYMENT MODE",
      dataIndex: "payment_mode",
      // sorter: (a, b) => a.payment_mode.localeCompare(b.payment_mode),
      width: 100, // Fixed width
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      // sorter: (a, b) => a.email.localeCompare(b.email),
      width: 100, // Fixed width
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      // sorter: (a, b) => a.amount.localeCompare(b.amount),
      width: 100, // Fixed width
    },

    {
      title: "STATUS",
      dataIndex: "status",
      width: 100, // Fixed width
    },
  ];
const TransactionModal = ({ fromTitle, open, handleCancel,transactionHistory }) => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { billerTransactionHistoryData, totalData, loading } = useSelector(
    (state) => state.billerReducer
  );
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });

  var data =
  billerTransactionHistoryData?.transactions &&
  billerTransactionHistoryData?.transactions.length > 0 &&
  billerTransactionHistoryData?.transactions.map((items, index) => {
      return {
        key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        transaction_id: items?.transactionId,
        email: items?.email,
        payment_mode: items?.paymentMode,
        amount: `₹${items?.amount}`,
        date: (
          <>
            <p className="m-0 p-0">
              {moment(items.createDate).format("DD MMM YYYY HH:MM:SS A")}
            </p>
          </>
        ),
        status: (
          <Tag
            color={
              items.status === "Succcesful"
                ? "green"
                : items.status === "Pending"
                ? "yellow"
                : "red"
            }
            className="draft"
          >
            {items.status === "Succcesful"
              ? "Successful"
              : items.status === "Pending"
              ? "Pending"
              : items.status}
          </Tag>
        ),
      };
    });
  const handlePageChange = async (pagination, sofilters, rter) => {
    const { current, pageSize } = pagination;
    setTableParams({ current, pageSize });
    if (fromDate && toDate) {
      dispatch(getBillerTransactionHistory(current, pageSize, "", fromDate?.format('YYYY-MM-DD'), toDate?.format('YYYY-MM-DD')));
  } else {

      dispatch(getBillerTransactionHistory(current, pageSize));
  }
}
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const disabledToDate = (current) => {
    return (
      (fromDate &&
        current &&
        (current < fromDate.startOf("day") ||
          current > moment().startOf("day"))) ||
      (current && current > moment().startOf("day"))
    );
  };
  
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  
  const handleToDateChange = (date) => {
    setToDate(date);
    if (date) {
      const fromSendDate = fromDate?.format("YYYY-MM-DD");
      const toDate = date?.format("YYYY-MM-DD");
      dispatch(getBillerTransactionHistory(transactionHistory?.billerId, 1,10,"", fromSendDate, toDate));
    }else{
      dispatch(getBillerTransactionHistory(transactionHistory?.billerId, 1,10,"", "", ""));

    }
  };

  let timeoutId;
  const handleFilterChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      dispatch(getBillerTransactionHistory(transactionHistory?.billerId, 1,10,value));
    }, 1000);
  };

  return (
    <Modal
      title={fromTitle}
      open={open}
      onCancel={handleCancel}
      width={1000}
      maskClosable={false}
      footer={null}
    >
      <div className="mainContain" >
        <div className="datesearchFi">
          <div className="">
            <SearchBar onChange={handleFilterChange}  />
          </div>
          <div className="inputfatef">
            <div>
              <DatePicker
                value={fromDate}
                style={{ width: "100%", border: "none",backgroundColor: '#f0f0f0' }}
                onChange={handleFromDateChange}
                disabledDate={disabledDate}
                format="DD-MM-YYYY"
                placeholder="From (DD-MM-YYYY)"
                suffixIcon={
                  <CalendarOutlined
                    style={{ color: "#d9d3d3" }}
                  />
                }
              />
            </div>
            <div>
              <DatePicker
                value={toDate}
                style={{ width: "100%", border: "none",backgroundColor: '#f0f0f0' }}
                onChange={handleToDateChange}
                disabledDate={disabledToDate}
                format="DD-MM-YYYY"
                placeholder="To (DD-MM-YYYY)"
                suffixIcon={
                  <CalendarOutlined
                    style={{ color: "#d9d3d3" }}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            scroll={{ x: 600 }}
            pagination={{
              total: {totalData},
              current: tableParams.current,
              pageSize: tableParams.pageSize,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "50"],
            }}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </Modal>
  );
};

TransactionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default TransactionModal;
