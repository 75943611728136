import React from "react";
import "./overview.scss";
import TransactionData from "./overview components/TransactionData";
import BillerPerformance from "./overview components/BillerPerformance";
import BillingCycleGraph from "./overview components/BillingCycleGraph";
import SimplData from "./overview components/SimplData";

function Overview() {
  return (
    <div className="main">
        <div className="part1">
          <TransactionData />
        </div>
        <div className="part2">
          <div className="internalPart2">
          <div className="w-100"> <BillingCycleGraph /></div>
            <div className="w-100"><SimplData /></div>
          </div>
          <BillerPerformance />
        </div>
    </div>
  );
} 

export default Overview;
