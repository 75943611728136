import React, { useState } from 'react';
import moment from 'moment';

function Table({ columns, data, pagination, loading, onRowClick, tableFixed, paginationClass, totalPage }) {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const sortedData = () => {
        if (!Array.isArray(data)) {
            return [];
        }
        const sortableData = [...data];
        if (sortConfig.key !== null) {
            sortableData.sort((a, b) => {
                let comparison = 0;
                const keyA = a[sortConfig.key];
                const keyB = b[sortConfig.key];
                if (keyA > keyB) {
                    comparison = 1;
                } else if (keyA < keyB) {
                    comparison = -1;
                }
                return sortConfig.direction === 'descending' ? comparison * -1 : comparison;
            });
        }
        return sortableData;
    };

    const paginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedData().slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(totalPage / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li className={`page-item ${currentPage === i ? 'active' : ''}`} key={i}>
                    <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
                </li>
            );
        }
        return pages;
    };

    return (
        <div className={pagination ? `table-pagination-class` : ''}>
            <table id="user-list-table" className={`table ${tableFixed} table-striped table-bordered mt-3`} role="grid">
                <thead style={{ backgroundColor: "rgb(217 211 211)" }}>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index} onClick={() => setSortConfig({ key: column.key, direction: sortConfig.direction === 'ascending' ? 'descending' : 'ascending' })}>
                                {column.displayName}
                                {sortConfig.key === column.key && (
                                    sortConfig.direction === 'ascending' ? '↓' : '↑'
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className='bg-white'>
                    {!loading && paginatedData().map((row, rowIndex) => (
                        <tr key={rowIndex} style={{ cursor: 'pointer' }} onClick={() => onRowClick && onRowClick(row)}>
                            {columns.map((column, colIndex) => (
                                <td key={colIndex}>{column.key === 'date' ? moment(row[column.key]).format("YYYY-MM-DD HH:mm:ss") : row[column.key]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {loading && <div className="spinnerInner"></div>}
            {pagination && data.length > 0 && !loading && (
                <div className={`row  bg-white justify-content-between mt-md-3 mt-lg-0 align-items-center ${paginationClass}`}>
                    <div id="user-list-page-info" className="col-md-6">
                        <span>{`Showing ${(currentPage - 1) * itemsPerPage + 1} to ${Math.min(currentPage * itemsPerPage, data.length)} of ${data.length} entries`}</span>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination mb-0">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)} tabIndex="-1" aria-disabled={currentPage === 1}>
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                {renderPageNumbers()}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                                <li className="page-item ml-3">
                                    <select className="page-link" style={{ borderRadius: "0.25rem" }} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))} value={itemsPerPage}>
                                        <option value={10}>10 / page</option>
                                        <option value={20}>20 / page</option>
                                        <option value={50}>50 / page</option>
                                        <option value={100}>100 / page</option>
                                    </select>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}
        </div>
    );
}

Table.defaultProps = {
    pagination: true
};

export default Table;
