import React, { useEffect } from 'react'
import { Button, Form, Input, message } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthActions from '../../redux/auth/actions';

import './ForgetPassword.scss';

function ForgetPassword() {
    const [messageApi,contextHolder]=message.useMessage()
    const { forgotPassword, forgotPasswordErrorReset } = AuthActions
    const dispatch = useDispatch()
    const {forgetPasswordResponse} = useSelector((state)=>{
        return {
            forgetPasswordResponse:state.authReducer.forgetPassword
        }
    })


    const onFinish = (values) => {
        dispatch(forgotPassword({
            origin:`${window.location.origin}`,
            email:values.email
        }))
    };

    useEffect(() => {
        if (forgetPasswordResponse.error !== '' && forgetPasswordResponse.error?.length > 0 && forgetPasswordResponse.loading === false) {
            setTimeout(() => {
                messageApi.open({
                    type: 'error',
                    content: forgetPasswordResponse.error,
                    duration: 1
                })
                dispatch(forgotPasswordErrorReset())
            }, 500);
        } else if (forgetPasswordResponse?.response !== null && forgetPasswordResponse?.response?.length > 0) {
            setTimeout(() => {
                messageApi.open({
                    type: forgetPasswordResponse?.response.includes('updated' || 'success' || 'email') ? 'success' : 'error',
                    content: forgetPasswordResponse?.response,
                    duration: 1
                })
            }, 500);
        }
    }, [forgetPasswordResponse, messageApi, forgotPasswordErrorReset, dispatch])

    return (
        <div className="d-flex password-main-div justify-content-center align-items-center">
            {contextHolder}
            <section className="vh-100">
                <div className="container d-flex align-items-center py-5 h-100">
                    <div className="row d-flex password-container align-items-center justify-content-center" id='main-div'>
                        <div className="col-md-6 col-lg-7 col-xl-6">
                            <img src={`/fp.svg`} className='img-fluid' alt='forgot-password' />
                        </div>
                        <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1" id='form-div'>
                            <Form
                                name="forgetForm"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                style={{
                                    maxWidth: 800,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',

                                        },
                                        { type: 'email', message: 'Please input valid email id !' },
                                    ]}
                                >
                                    <Input placeholder='Enter your email' />
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Button type="primary" style={{ background: "#164cff" }} htmlType="submit" block>
                                        Forget Password
                                    </Button>
                                </Form.Item>
                            </Form>
                            <span className="small text-muted">Remembered your password? </span> <Link to="/login" className="small text-muted" >Login</Link>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ForgetPassword
