import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker, Table } from "antd";
import { CalendarOutlined, DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import emailActions from "../../redux/communication_channel/actions";
import { CSVLink } from "react-csv";
import { generateFilename } from "../../utils/exportFileName";
import SearchBar from "../../utils/Searchbar";

const { getMailsData } = emailActions;

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    sorter: (a, b) => a?.key - b?.key,
    width: 100,
  },
  {
    title: "DATE",
    dataIndex: "date",
    width: 100, // Fixed width
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 100, // Fixed width
  },
  {
    title: "Subject",
    dataIndex: "subject",
    width: 100, // Fixed width
  },
  {
    title: "Sent At",
    dataIndex: "sentAt",
    width: 100, // Fixed width
  },
  {
    title: "Body",
    dataIndex: "body",
    width: 100, // Fixed width
  },
  {
    title: "Attachment",
    dataIndex: "attachmant",
    width: 50, // Fixed width
  },
];
function Mails() {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const { mailsData, totalData, loading } = useSelector(
    (state) => state.communicationReducer
  );
  const { agentId } = useSelector((state) => ({
    agentId: state.authReducer.agentId,
  }));

  var data =
    mailsData &&
    mailsData.length > 0 &&
    mailsData.map((items, index) => {
      return {
        key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        date: (
          <>
            <p className="m-0 p-0">
              {moment(items.createTime).format("DD MMM YYYY")}
            </p>
          </>
        ),
        email:items?.email,
        subject:items?.subject,
        sentAt: (
          <>
            <p className="m-0 p-0">
              {moment(items.sentAt).format("DD MMM YYYY")}
            </p>
          </>
        ),
        body: (<>
          <textarea className="form-control bg-white" value={items?.body} readOnly rows={5} cols={2} style={{ resize: "both", overflow: "auto", minWidth: "200px", minHeight:"200px", scrollbarWidth: "thin" }}></textarea>
        </>),
        attachmant: (
          <>
            {Array.isArray(items.attachments) && items.attachments.length > 0 ? (
              <div className="bg-blue-600">
                {
                  items.attachments.map((attachment, index) => (
                    <a
                      href={attachment}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      className="mx-2"
                      download={true}
                    >
                      <DownloadOutlined />
                    </a>
                  ))
                }
              </div>
            ) : (
              <p className="m-0 p-0">No Attachments</p>
            )}
          </>
        ),
      };
    });


  let timeoutId;
  const handleFilterChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear the previous timeout if it exists
    }

    timeoutId = setTimeout(() => {
      if (mailsData?.length >= 0) {
        dispatch(getMailsData(0, 10, value));
      }
    }, 1000);
  };

  const handlePageChange = async (pagination, sofilters, rter) => {
    const { current, pageSize } = pagination;
    setTableParams({ current, pageSize });

    if (fromDate && toDate) {
      dispatch(
        getMailsData(
          current,
          pageSize,
          "",
          fromDate?.format("YYYY-MM-DD"),
          toDate?.format("YYYY-MM-DD")
        )
      );
    } else {
      dispatch(getMailsData(current, pageSize));
    }
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const fromSendDate = fromDate?.format("YYYY-MM-DD");
    const toDate = date?.format("YYYY-MM-DD");
    if (fromSendDate && toDate) {
      dispatch(getMailsData(1, 10, "", fromSendDate, toDate));
    } else {
      dispatch(getMailsData(1, 10));
    }
  };

  const disabledToDate = (current) => {
    return (
      (fromDate &&
        current &&
        (current < fromDate.startOf("day") ||
          current > moment().startOf("day"))) ||
      (current && current > moment().startOf("day"))
    );
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const exportData =
    mailsData &&
    mailsData.length > 0 &&
    mailsData.map((items, index) => {
      return {
        key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        date: moment(items.createTime).format("DD MMMM YYYY"),
        email:items?.email,
        subject:items?.subject,
        sentAt: moment(items.sentAt).format("DD MMMM YYYY"),
        body:items?.body,
        attachmant: items?.attachments ? items?.attachments.join(", ") : "No Attachments",
      };
    });

  useEffect(() => {
    if (!agentId || agentId === "") {
      dispatch(getMailsData());
    } else {
      dispatch(getMailsData(0, 10, "", "", "", agentId));
    }
  }, [agentId, dispatch]);

  return (
    <div className="mainContain">
      <h1 className="transaction">Mails</h1>
      <div className="datesearchFi">
        <div className="">
          <SearchBar onChange={handleFilterChange} />
        </div>
        <div className="inputfatef">
          <div>
            {/* <span>From (DD-MM-YYYY)</span> */}
            <DatePicker
              value={fromDate}
              style={{ width: "100%", border: "none" }}
              onChange={handleFromDateChange}
              disabledDate={disabledDate}
              format="DD-MM-YYYY"
              placeholder="From (DD-MM-YYYY)" // Placeholder added here
              suffixIcon={
                <CalendarOutlined style={{ color: "rgba(22, 22, 22, 0.25)" }} />
              }
            />
          </div>
          <div>
            {/* <span>To (DD-MM-YYYY)</span> */}
            <DatePicker
              value={toDate}
              style={{ width: "100%", border: "none" }}
              onChange={handleToDateChange}
              disabledDate={disabledToDate}
              format="DD-MM-YYYY"
              placeholder="To (DD-MM-YYYY)" // Placeholder added here
              suffixIcon={
                <CalendarOutlined style={{ color: "rgba(22, 22, 22, 0.25)" }} />
              }
            />
          </div>
        </div>
      </div>
      {data?.length > 0 ? (
        <div className="exportBtnDiv mt-3">
          <CSVLink
            filename={generateFilename("complaints_data_")}
            data={exportData ? exportData : []}
            className="btn exportBtn"
          >
            <DownloadOutlined />
            Export
          </CSVLink>
        </div>
      ) : (
        ""
      )}

      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          scroll={{ x: 600 }}
          pagination={{
            total: totalData,
            current: tableParams.current,
            pageSize: tableParams.pageSize,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ["10", "20", "50"],
          }}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Mails;
