import React, { useEffect, useState } from "react";
import "./biller.scss";
import moment from "moment";
import { DatePicker, Table, Tag } from "antd";
import { CalendarOutlined ,   EyeFilled,
  DownloadOutlined,
  EyeInvisibleFilled, } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import billerActions from "../../redux/GetBillerData/actions";
import { CSVLink } from "react-csv";
import SearchBar from "../../utils/Searchbar";
import HistoryModel from "./TransactionHistory/Model"

const { getBillerData , getBillerTransactionHistory } = billerActions;

function Biller() {
  const dispatch = useDispatch();
  const { billerData, totalData, loading } = useSelector(
    (state) => state.billerReducer
  );
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const now = moment();
  const formattedDate = now.format("YYYY-MM-DD");
  const formattedTime = now.format("HH-mm-ss");
  const newFilename = `billers_data_${formattedDate}_${formattedTime}.csv`;
  const [historyModel, setHistoryModel] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
 

  const handleCancel = () => {
    setHistoryModel(false);
    setTransactionHistory([]);
  };

  useEffect(() => {
    dispatch(getBillerData());
  }, []);


  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      sorter: (a, b) => a?.key-b?.key,
      width: 100, // Fixed width
    },
    {
      title: "Biller Name",
      dataIndex: "billerName",
      width: 100, // Fixed width
    },
    {
      title: "Biller ID",
      dataIndex: "billerId",
      // sorter: (a, b) => a.transaction_id.localeCompare(b.transaction_id),
      width: 100, // Fixed width
    },
    {
      title: "Biller Mode",
      dataIndex: "billerMode",
      // sorter: (a, b) => a.payment_mode.localeCompare(b.payment_mode),
      width: 100, // Fixed width
    },
    {
      title: "Biller Category Name",
      dataIndex: "billerCategoryName",
      // sorter: (a, b) => a.email.localeCompare(b.email),
      width: 100, // Fixed width
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      // sorter: (a, b) => a.amount.localeCompare(b.amount),
      width: 100, // Fixed width
    },

    {
      title: "Status",
      dataIndex: "Status",
      width: 100, // Fixed width
    },
    {
      title: "HISTORY",
      dataIndex: "history",
      width: 100, // Fixed width
    },
  ];

  var data =
  billerData &&
  billerData?.length > 0 &&
  billerData?.map((items, index) => {
      return {
        key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        billerName: items?.billerName,
        billerId: items?.billerId,
        billerMode: items?.billerMode,
        billerCategoryName: items?.billerCategoryName,
        totalAmount: items?.totalAmount,
        date: (
          <>
            <p className="m-0 p-0">
              {moment(items.createDate).format("DD MMM YYYY HH:MM:SS A")}
            </p>
            {/* <p className="m-0 p-0">
              {moment(new Date(items.createDate)).format("hh:mm:ss A")}
            </p> */}
          </>
        ),
        Status: (
          <Tag
            color={
              items.Status === "Active"
                ? "green"
                : items.Status === "Pending"
                ? "yellow"
                : "red"
            }
            className="draft"
          >
            {items.Status === "Active"
              ? "Active"
              : items.Status === "Pending"
              ? "Pending"
              : items.Status}
          </Tag>
        ),
        history: (
          <>
            {historyModel ? (
              <EyeInvisibleFilled
                style={{
                  fontSize: "18px",
                  color: "#073763",
                  marginLeft: "10px",
                }}
                onClick={handleCancel}
              />
            ) : (
              <EyeFilled
                style={{
                  fontSize: "18px",
                  color: "#073763",
                  marginLeft: "10px",
                }}
                onClick={() => handleViewHistroy(items)}
              />
            )}
          </>
        ),
      };
    });


    const handleViewHistroy = (data) => {
      dispatch(getBillerTransactionHistory(data?.billerId))
      setHistoryModel(true);
      setTransactionHistory(data);
    };

  
  let timeoutId;
  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if(billerData?.length >= 0){
        dispatch(getBillerData(0, 10, value)); 
      }      
    }, 1000);
  };

  const handlePageChange = async (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setTableParams({ current, pageSize });
    if (fromDate && toDate) {
      dispatch(
        getBillerData(
          current,
          pageSize,
          "",
          fromDate?.format("YYYY-MM-DD"),
          toDate?.format("YYYY-MM-DD")
        )
      );
    } else {
      dispatch(getBillerData(current, pageSize));
    }
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const fromSendDate = fromDate?.format("YYYY-MM-DD");
    const toDate = date?.format("YYYY-MM-DD");
    if (fromSendDate && toDate) {
      dispatch(getBillerData(1, 10, "", fromSendDate, toDate));
    }else{
      dispatch(getBillerData(1, 10));
    }
  };

  const disabledToDate = (current) => {
    return (
      (fromDate &&
        current &&
        (current < fromDate.startOf("day") ||
          current > moment().startOf("day"))) ||
      (current && current > moment().startOf("day"))
    );
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };


  const exportData =
  billerData &&
  billerData?.length > 0 &&
  billerData?.map((items, index) => {
      return {
        billerName: items?.billerName,
        billerId: items?.billerId,
        billerMode: items?.billerMode,
        billerCategoryName: items?.billerCategoryName,
        totalAmount: items?.totalAmount,
      };
    });

  return (
    <div className="mainContain">
      <h1 className="transaction">Billers</h1>
      <div className="datesearchFi">
        <div className="">
        <SearchBar onChange={handleSearch}  />
        </div>
        <div className="inputfatef">
          <div>
            <DatePicker
              value={fromDate}
              style={{ width: "100%", border: "none" }}
              onChange={handleFromDateChange}
              disabledDate={disabledDate}
              format="DD-MM-YYYY"
              placeholder="From (DD-MM-YYYY)" // Placeholder added here
              suffixIcon={
                <CalendarOutlined style={{ color: "#d9d3d3" }} />
              }
            />
          </div>
          <div>
            <DatePicker
              value={toDate}
              style={{ width: "100%", border: "none" }}
              onChange={handleToDateChange}
              disabledDate={disabledToDate}
              format="DD-MM-YYYY"
              placeholder="To (DD-MM-YYYY)"
              suffixIcon={
                <CalendarOutlined style={{ color: "#d9d3d3" }} />
              }
            />
          </div>
        </div>
      </div>
      <div className="cardClassSingleButon">
        <div className="exportBtnDiv">
        {data?.length > 0?
          <CSVLink
            filename={newFilename}
            data={exportData ? exportData : []}
            className="btn exportBtn"
          >
            <DownloadOutlined />
            Export
          </CSVLink> : ""}
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          scroll={{ x: 600 }}
          pagination={{
            total: totalData,
            current: tableParams.current,
            pageSize: tableParams.pageSize,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ["10", "20", "50" , "100"],
          }}
          onChange={handlePageChange}
        />
      </div>
      <HistoryModel
        fromTitle="Transaction History of Biller"
        open={historyModel}
        handleCancel={handleCancel}
        transactionHistory={transactionHistory}
      />
    </div>
  );
}

export default Biller;
