import React from 'react'

function Unauthorized() {
    return (
        <div className='mt-5 text-center text-danger'>
            404 - Page Not Found. You are not authorized.
        </div>
    )
}

export default Unauthorized
