import { Route, Routes, Navigate } from "react-router-dom"
import Login from "../../Pages/Auth/Login"
import ForgetPassword from "../../Pages/Auth/ForgetPassword"
import ResetPassword from "../../Pages/ResetPassword/ResetPassword"

function LoginRoutes() {
    return (
        <Routes>
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
    )
}

export default LoginRoutes
