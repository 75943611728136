import { all, takeEvery, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import { getData, postData, patchData, deleteData } from '../../Services/ApiMethodHelper';

function* getCategoriesResponse(action) {
    try {
        const { search, countPerpage, currentPage } = action.payload; 
        const response = yield call(getData, `/category?limit=${countPerpage}&page=${currentPage}&search=${search}`);
        if (response) {
            yield put(actions.getCategoriesSuccess(response));
        } else {
            yield put(actions.getCategoriesFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getCategoriesFailed(error.Message));
    }
}

function* createCategoryResponse(action) {
    try {
        const response = yield call(postData, '/category', action.data);
        if (response) {
            yield put(actions.createCategorySuccess(response));
        } else {
            yield put(actions.createCategoryFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.createCategoryFailed(error.Message));
    }
}

function* updateCategoryResponse(action) {
    try {
        const response = yield call(patchData, `/category/${action?.data?.id}`, action.data);
        if (response) {
            yield put(actions.updateCategorySuccess(response));
        } else {
            yield put(actions.updateCategoryFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updateCategoryFailed(error.Message));
    }
}

function* deleteCategoryResponse(action) {
    try {
        const response = yield call(deleteData, `/category/${action.id}`);
        if (response) {
            yield put(actions.deleteCategorySuccess(action.id));
        } else {
            yield put(actions.deleteCategoryFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.deleteCategoryFailed(error.Message));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_CATEGORIES_DATA, getCategoriesResponse),
        takeEvery(actions.CREATE_CATEGORY_DATA, createCategoryResponse),
        takeEvery(actions.UPDATE_CATEGORY_DATA, updateCategoryResponse),
        takeEvery(actions.DELETE_CATEGORY_DATA, deleteCategoryResponse),
    ]);
}
