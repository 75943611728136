import actions from './actions';

const initState = {
  loading: false,
  message: null,
  buttonLoading: false,
  error: null,
  userData: [],
  modulesData: [],
  rolesData: [],
  totalData: null,
  show: false
};


export default function authReducer(state = initState, action) {
  switch (action.type) {

    case actions.GET_USER_DATA:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload.data,
        totalData: action.payload.totalData
      };
    case actions.GET_USER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.ADD_USER:
      return {
        ...state,
        buttonLoading: true,
      };
    case actions.ADD_USER_SUCCESS:
      return {
        ...state,
        show: false,
        buttonLoading: false,
        message: 'User added successfully'
      };
    case actions.ADD_USER_FAILED:
      return {
        ...state,
        buttonLoading: false,
        show: false,
        error: action.payload.error,
      };

    case actions.EDIT_USER:
      return {
        ...state,
        buttonLoading: true,
      };
    case actions.EDIT_USER_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        show: false,
        message: 'User edit successfully'
      };
    case actions.EDIT_USER_FAILED:
      return {
        ...state,
        buttonLoading: false,
        show: false,
        error: action.payload.error,
      };
    case actions.DELETE_USER:
      return {
        ...state,
        buttonLoading: true,
      };
    case actions.DELETE_USER_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        message: 'User deactive successfully'
      };
    case actions.DELETE_USER_FAILED:
      return {
        ...state,
        buttonLoading: false,
        error: action.payload.error,
      };

    case actions.USER_STATUS_CHANGE:
      return {
        ...state,
        buttonLoading: true,
      };
    case actions.USER_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        message: 'User active successfully'
      };
    case actions.USER_STATUS_CHANGE_FAILED:
      return {
        ...state,
        buttonLoading: false,
        error: action.payload.error,
      };

    case actions.GET_ROLES:
      return {
        ...state,
      };
    case actions.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        rolesData: action.rolesData,
      };
    case actions.GET_ROLES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case actions.GET_MODULES:
      return {
        ...state,
      };
    case actions.GET_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        modulesData: action.modulesData,
      };
    case actions.GET_MODULES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case actions.OPEN_USER_FORM:
      return {
        ...state,
        show: true,
      };

    case actions.USER_RESET:
      return {
        ...state,
        show: false
      };

    case actions.USER_RESET_MESSAGE:
      return {
        ...state,
        loading: false,
        message: null,
        error: null,
        show: false
      };



    default:
      return state;
  }

}
