import React, { useEffect } from "react";
import { Button, Form, Input, Typography, Row, Col, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AuthActions from "../../redux/auth/actions";

const { Title } = Typography;

const ResetPassword = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { resetPassword } = AuthActions;
  const urlParams = new URLSearchParams(window.location.search);  
  const auth_token = urlParams.get('token');

  const dispatch = useDispatch();

  const { resetPasswordResponse } = useSelector((state) => {
    return {
      resetPasswordResponse: state?.authReducer?.resetPassword,
    };
  });

  const onFinish = (values) => {
    dispatch(
      resetPassword({
          values: values,
          token: localStorage.getItem("resetPasswordToken"),
      })
    );
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(()=>{
    if(auth_token?.length>0){
      localStorage.setItem("resetPasswordToken",auth_token)
      window.history.pushState('','',`${window.location.origin}/reset-password`)
    }
  },[auth_token])

  useEffect(() => {
    if (resetPasswordResponse?.response?.message === "Password updated successfully") {
      messageApi.open({
        type: "success",
        content: "password updated!",
        duration: 0.5,
      }).then(()=>message.loading('redirecting to login page'))
      setTimeout(() => {
        window.location.replace(`${window.location.origin}/login`);
      }, 1000);
      localStorage.removeItem("resetPasswordToken")
    }else if(resetPasswordResponse?.error!=='') {
      messageApi.open({
        type:'error',
        content:resetPasswordResponse?.error,
        duration:1
      })
      localStorage.removeItem("resetPasswordToken")
    }
  }, [resetPasswordResponse,messageApi]);

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f0f2f5",
          padding: "20px",
        }}
      >
        <div
          style={{
            maxWidth: "400px",
            width: "100%",
            background: "#fff",
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
          }}
        >
          <Row justify="center" style={{ marginBottom: "20px" }}>
            <Col>
              <Title level={4} style={{ textAlign: "center" }}>
                Reset Your Password
              </Title>
            </Col>
          </Row>

          <Form
            name="resetPassword"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your new password!",
                },
                {
                  min: 6,
                  message: "Password must be at least 6 characters!",
                },
              ]}
            >
              <Input.Password placeholder="Enter new password" />
            </Form.Item>

            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm new password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{
                  height: "45px",
                  fontSize: "16px",
                  backgroundColor: "rgb(22, 76, 255)",
                }}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
