const actions = {
    GET_BILLER_DATA: 'GET_BILLER_DATA',
    GET_BILLER_DATA_SUCCESS: 'GET_BILLER_DATA_SUCCESS',
    GET_BILLER_DATA_FAILED: 'GET_BILLER_DATA_FAILED',
    GET_BILLER_TRANSACTION_HISTORY: 'GET_BILLER_TRANSACTION_HISTORY',
    GET_BILLER_TRANSACTION_HISTORY_SUCCESS: 'GET_BILLER_TRANSACTION_HISTORY_SUCCESS',
    GET_BILLER_TRANSACTION_HISTORY_FAILED: 'GET_BILLER_TRANSACTION_HISTORY_FAILED',
  
    getBillerData: (offset, limit, search, fromDate, toDate) => ({
      type: actions.GET_BILLER_DATA,
      payload: { offset, limit, search, fromDate, toDate },
    }),

    getBillerDataSuccess: (data, totalData) => ({
      type: actions.GET_BILLER_DATA_SUCCESS,
      payload: { data, totalData },
    }),

    getBillerDataFailed: (error) => ({
      type: actions.GET_BILLER_DATA_FAILED,
      payload: { error },
    }),

    getBillerTransactionHistory: (billerId ,offset, limit, search, fromDate, toDate) => ({
      type: actions.GET_BILLER_TRANSACTION_HISTORY,
      payload: {billerId , offset, limit, search, fromDate, toDate },
    }),

    getBillerTransactionHistorySuccess: (data, totalData) => ({
      type: actions.GET_BILLER_TRANSACTION_HISTORY_SUCCESS,
      payload: { data, totalData },
    }),
    
    getBillerTransactionHistoryFailed: (error) => ({
      type: actions.GET_BILLER_TRANSACTION_HISTORY_FAILED,
      payload: { error },
    }),

  };
  
  export default actions;
  