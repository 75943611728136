import actions from './actions';

const initState = {
  loading: false,
  message: null,
  buttonLoading: false,
  error: null,
  billerData: {},
  billerTransactionHistoryData: {},
  totalData: null,
  show: false
};

export default function billerReducer(state = initState, action) {
    switch (action.type) {
    case actions.GET_BILLER_DATA:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_BILLER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        billerData: action.payload.data,
        totalData: action.payload.totalData,
      };
    case actions.GET_BILLER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case actions.GET_BILLER_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_BILLER_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        billerTransactionHistoryData: action.payload.data,  
        totalData: action.payload.totalData, 
      };
    case actions.GET_BILLER_TRANSACTION_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
