import React, { useState } from "react";
import { Form, Button, Upload, Typography, Space, Divider, Card } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { useSelector } from "react-redux";

const { Dragger } = Upload;
const { Text, Title } = Typography;

const baseURLBOU = process.env.REACT_APP_API_BASE_URL;

const FileUploadForm = () => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(""); // Store the uploaded file URL
  const [loading, setLoading] = useState(false);
  const [fileMessage, setFileMessage] = useState("");
  const { billerId } = useSelector((state) => ({
    billerId: state.authReducer.agentId,
  }));

  const handleUpload = async ({ file }) => {
    const allowedFileTypes = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!allowedFileTypes.includes(file.type)) {
      setFileMessage("Only CSV and XLSX files are allowed.");
      setFile(null);
      setFileUrl("");
      return;
    }

    setFile(file);
    setFileMessage(`File selected: ${file.name}`);
    
    // Automatically upload the file
    await handleUploadFile(file);
  };

  const handleUploadFile = async (file) => {
    const formData = new FormData();
    formData.append("files", file);
    // formData.append("billerId", billerId);

    setFileMessage("Uploading file...");

    try {
      const response = await axios.post(
        `${baseURLBOU}v1/repco`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201 && response.data.urls.length > 0) {
        setFileMessage("File uploaded successfully.");
        setFileUrl(response.data.urls[0]); // Store the uploaded file URL
      } else {
        setFileMessage("File upload failed.");
        setFileUrl("");
      }
    } catch (error) {
      setFileMessage("File upload failed.");
      setFileUrl("");
    }
  };

//   const handleSubmit = async () => {
//     if (!fileUrl) {
//       setFileMessage("Please upload a file before submitting.");
//       return;
//     }
//     setLoading(true);
//     // const data = {
//     //   billerId: billerId,
//     //   attachments: formData,
//     // };
//     setFileMessage("Submitting data...");
//     try {
//       const response = await axios.post(
//         `${baseURLBOU}v1/repco`,
//         data
//       );
//       if (response.status === 200) {
//         setFileMessage("Data submitted successfully.");
//       } else {
//         setFileMessage("Submission failed.");
//       }
//     } catch (error) {
//       setFileMessage("Submission failed.");
//     } finally {
//       setLoading(false);
//     }
//   };

  return (
    <Card
      title={<Title level={4}>Upload CSV or XLSX File</Title>}
      style={{ maxWidth: 500, margin: "20px auto", padding: 20, boxShadow: "0px 4px 8px rgba(0,0,0,0.1)" }}
    >
      <Form layout="vertical">
        <Form.Item label="Upload File" required>
          <Dragger
            accept=".csv, .xlsx"
            beforeUpload={() => false}
            onChange={handleUpload}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: "#1890ff", fontSize: 48 }} />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Only CSV and XLSX files are supported.</p>
          </Dragger>
        </Form.Item>

        {fileMessage && (
          <Text
            style={{
              display: "block",
              marginTop: 10,
              color: fileMessage.includes("successfully") ? "green" : "red",
            }}
          >
            {fileMessage}
          </Text>
        )}

        <Divider />

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading} disabled={!fileUrl}>
              Submit
            </Button>
            <Button type="default" onClick={() => window.location.reload()}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default FileUploadForm;
