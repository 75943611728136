// saga.js
import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import { getDataFromBOU , getData, postData} from '../../Services/ApiMethodHelper';
const baseURLBOU = process.env.REACT_APP_API_BASE_URL;


function* addCommunicationResponse({ payload: { data } }) {
  try {
    const response = yield call(postData, `${baseURLBOU}bou/dashboard/api/communication-channel` ,data);

    if (response.status === 201) {
      console.log(response , "response")
      yield put(
        actions.addCommunicationResponseSuccess(
          "Communication response added successfully"
        )
      );
    } else {
      yield put(actions.addCommunicationResponseFailed(response.data.message));
    }
  } catch (error) {
    yield put(
      actions.addCommunicationResponseFailed(error?.response?.data?.message)
    );
  }
}

function* getMailsDataResponse({
  payload: { offset, limit, search, fromDate, toDate, billerId, agentId },
}) {
  try {
    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/api/communication-channel?&page=${offset ? offset : 1}&limit=${limit ? limit : 10}${search ? `&search=${search}` : ''}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${ billerId ? `&billerId=${billerId}` : ""}${agentId ? `&billerId=${agentId}` : ""}`);

    yield put(actions.getMailsDataSuccess(response.data.data, response.data.data.total));
  } catch (error) {
    yield put(actions.getMailsDataFailed(error));
  }
}

function* getOrganizations({
  payload: { offset, limit, search },
}) {
  try {
    const response = yield call(getData, `/admin/organizations/bou?offset=${offset ? offset : 0}&limit=${limit ? limit : 10}${search ? `&search=${search} ` : ''}`);
    console.log(response , "response")
    yield put(actions.getOrganizationsDataSuccess(response.data, response.data.total));
  } catch (error) {
    yield put(actions.getOrganizationsDataFailed(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ADD_COMMUNICATION_RESPONSE, addCommunicationResponse)]);
  yield all([takeEvery(actions.GET_MAILS_DATA, getMailsDataResponse)]);
  yield all([takeEvery(actions.GET_ORGANIZATIONS_DATA, getOrganizations)]);
}
