import { Input } from 'antd';

function SearchBar({ onChange, style }) {
    return (
        <div className="input-group" style={{ maxWidth: '350px' }}>
            {/* <input
                type="text"
                className="form-control"
                style={style}
                placeholder="Type here to search..."
                onChange={onChange}
            /> */}
            <Input size="default size" placeholder ="Type here to search..." onChange={onChange} />

        </div>
    );
}

export default SearchBar;
