import React, { useState } from "react";
import reloadImg from "../../assets/icons/reload.svg";
import "./graph.scss";
import { Select, DatePicker } from "antd";
import TableData from "./TableData";

const NewSettlementReconciliation = () => {
  const options = [
    {
      value: "All Value ",
      label: "All Value",
    },
    {
      value: "last 6",
      label: "Last 6",
    },
    {
      value: "last 12",
      label: "Last 12",
    },
    {
      value: "last 18",
      label: "Last 18",
    },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const handleSelectChange = (value) => {
    console.log(selectedOption, "selectedOption");
    setSelectedOption(value);
  };

  return (
    <>
      <div className="fistheadingSearch">
        <div className="firstcomponet">
          <div>
            <h1 className="">Recharge Provider Reconciliation Result</h1>
          </div>
          <div className="d-flex">
            <div>
              <img src={reloadImg} alt="loading" />
            </div>
          </div>
        </div>
      </div>
      <div className="tableGraph">
        <div className="tableGraphItem">
          <div className="fistheadTable">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <p className="m-0 font-medium">Date:</p>
              <DatePicker />
            </div>
            <div className="d-flex justify-content-center align-items-center gap-3">
              <p className="m-0 font-medium">Cycle:</p>
              <Select
                variant="borderless"
                defaultValue={options[0].value}
                options={options}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <div className="tableSettlemts">
            <TableData/>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSettlementReconciliation;
