import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import SeeAll from "../../../assets/icons/seeAll.svg"
import billerActions from "../../../redux/GetBillerData/actions";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";


function BillerPerformance() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [tableParams,setTableParams]=useState({
        current:1,
        pageSize:10
    })
    const { getBillerData } = billerActions;
    const { billerData } = useSelector(
        (state) => state.billerReducer
    );
    const { permissions } = useSelector((state) => {
        return {
            permissions: state.authReducer.profileData.permissions
        }
    })

    // const { agentId } = useSelector((state) => ({
    //     agentId: state.authReducer.agentId,
    //   }));




    // useEffect(() => {
    //     // debugger
    //   if (!agentId || agentId=="") {
    //     dispatch(getBillerData());
    //   }else{
    //     dispatch(getBillerData(0, 10,"","","",  agentId));
    //   }

    // }, [agentId, dispatch]);

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Biller',
            dataIndex: 'billerName',
        },
        {
            title: 'Category',
            dataIndex: 'billerCategoryName',
        },
        {
            title: 'Success Rate',
            dataIndex: 'SuccessRate',
        },
        {
            title:'Failure Rate',
            dataIndex: 'FailureRate',
        },
        {
            title:'Txn/Day',
            dataIndex: 'transactionsPerDay'
        },
        {
            title:'Volume',
            dataIndex: 'totalTransactions'
        }
        
    ];

    const dataSource = billerData?.length>0&&billerData?.map((item,index)=>{
        return {
            key: (tableParams.current -1)*tableParams.pageSize+index+1,
            billerName:item?.billerName,
            billerCategoryName:item?.billerCategoryName,
            SuccessRate:<span style={{color:'#42BE65',fontWeight:'bold'}}>{item?.SuccessRate}%</span>,
            FailureRate:<span style={{color:'rgb(233, 33, 33)',fontWeight:'bold'}}>{item?.FailureRate}%</span>,
            transactionsPerDay:item?.transactionsPerDay,
            totalTransactions:item?.totalTransactions
        }
    })


    useEffect(() => {
        dispatch(getBillerData());
    }, []);

    const HandleChange = () => {
        navigate("/biller-performance");
    };

    return (
        <div>
            <div className="headingTag mt-2">
                <h1>Biller Performance</h1>
                {Array.isArray(permissions)?.length>0&&permissions?.some((item)=>item?.module==="transactions")&&<div onClick={HandleChange}>See All<img src={SeeAll} alt=""></img></div>}
            </div>
            <div className="tableComplaint">
                <Table columns={columns} dataSource={dataSource}/>
            </div>
        </div>
    )
}

export default BillerPerformance;
