import React, { useState } from "react";
import { Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const TableData = () => {
  const [dataSource, setDataSource] = useState([
    {
      billerId: "Biller_001",
      date: "2024-11-26",
      totalData: [
        {
          cycle: "1",
          totalTransaction: 1000,
          matchData: {
            totalNoOfMatch: 1200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
        {
          cycle: "2",
          totalTransaction: 1500,
          matchData: {
            totalNoOfMatch: 1200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
        {
          cycle: "3",
          totalTransaction: 1500,
          matchData: {
            totalNoOfMatch: 1200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
        {
          cycle: "4",
          totalTransaction: 1500,
          matchData: {
            totalNoOfMatch: 1200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
      ],
    },
    {
      billerId: "Biller_002",
      date: "2024-01-01",
      totalData: [
        {
          cycle: "1",
          totalTransaction: 1500,
          matchData: {
            totalNoOfMatch: 1200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
      ],
    },
    {
      billerId: "Biller_003",
      date: "2024-11-26",
      totalData: [
        {
          cycle: "1",
          totalTransaction: 2000,
          matchData: {
            totalNoOfMatch: 1200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
        {
          cycle: "2",
          totalTransaction: 1500,
          matchData: {
            totalNoOfMatch: 1200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
        {
          cycle: "3",
          totalTransaction: 1500,
          matchData: {
            totalNoOfMatch: 1200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
      ],
    },
    {
      billerId: "Biller_004",
      date: "2024-11-27",
      totalData: [
        {
          cycle: "1",
          totalTransaction: 1200,
          matchData: {
            totalNoOfMatch: 1000,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
        {
          cycle: "2",
          totalTransaction: 1800,
          matchData: {
            totalNoOfMatch: 1600,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
      ],
    },
    {
      billerId: "Biller_005",
      date: "2024-12-01",
      totalData: [
        {
          cycle: "1",
          totalTransaction: 2000,
          matchData: {
            totalNoOfMatch: 1500,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 500,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
      ],
    },
    {
      billerId: "Biller_006",
      date: "2024-12-02",
      totalData: [
        {
          cycle: "1",
          totalTransaction: 2200,
          matchData: {
            totalNoOfMatch: 2000,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 200,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
      ],
    },
    {
      billerId: "Biller_007",
      date: "2024-12-03",
      totalData: [
        {
          cycle: "1",
          totalTransaction: 1700,
          matchData: {
            totalNoOfMatch: 1400,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          unmatchData: {
            totalNoOfUnmatch: 300,
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          sal: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
          transactionReport: {
            url: "https://storage.googleapis.com/bbps-dev-b01/tsp/1732532682560_all%20billers%20data.zip",
          },
        },
      ],
    },
  ]);

  // Columns...
  const columns = [
    {
      title: "Biller Id",
      dataIndex: "billerId",
      key: "billerId",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  // Expandable Table...
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record.billerId]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  // Expanded Row Render...
  const expandedRowRender = (record) => {
    const nestedColumns = [
      {
        title: "Cycle",
        dataIndex: "cycle",
        key: "cycle",
      },
      {
        title: "Total Transactions",
        dataIndex: "totalTransaction",
        key: "totalTransaction",
      },
      {
        title: "Match Data",
        dataIndex: "matchData",
        key: "matchData",
        render: (matchData) => (
          <a
            href={matchData.url}
            target="_self"
            download={true}
            rel="noopener noreferrer"
          >
            {`Matches: ${matchData.totalNoOfMatch}`}
            <span className="mx-3">
              <DownloadOutlined />
            </span>
          </a>
        ),
      },
      {
        title: "Unmatch Data",
        dataIndex: "unmatchData",
        key: "unmatchData",
        render: (unmatchData) => (
          <a
            href={unmatchData.url}
            target="_self"
            download={true}
            rel="noopener noreferrer"
          >
            {`Unmatches: ${unmatchData.totalNoOfUnmatch}`}
            <span className="mx-3">
              <DownloadOutlined />
            </span>
          </a>
        ),
      },
      {
        title: "SAL File",
        dataIndex: "sal",
        key: "sal",
        render: (sal) => (
          <a
            href={sal.url}
            target="_self"
            download={true}
            rel="noopener noreferrer"
          >
            Download SAL
            <span className="mx-3">
              <DownloadOutlined />
            </span>
          </a>
        ),
      },
      {
        title: "Transaction Report",
        dataIndex: "transactionReport",
        key: "transactionReport",
        render: (transactionReport) => (
          <a
            href={transactionReport.url}
            target="_self"
            download={true}
            rel="noopener noreferrer"
          >
            Download Report
            <span className="mx-3">
              <DownloadOutlined />
            </span>
          </a>
        ),
      },
    ];

    return (
      <div className="mx-5">
        <Table
          columns={nestedColumns}
          dataSource={record.totalData}
          pagination={false}
          rowKey={(item) => item.cycle}
        />
      </div>
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      expandable={{
        expandedRowKeys: expandedRowKeys,
        onExpand: handleExpand,
        expandedRowRender: (record) => expandedRowRender(record),
      }}
      pagination={{
        pageSize: 5,

        position: ["bottomRight"],
      }}
      rowKey={(record) => record.billerId}
    />
  );
};

export default TableData;
