const actions = {
  USER_RESET_MESSAGE: 'USER_RESET_MESSAGE',
  USER_RESET: 'USER_RESET',
  GET_USER_DATA: 'GET_USER_DATA',
  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAILED: 'GET_USER_DATA_FAILED',
  ADD_USER: 'ADD_USER',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILED: 'ADD_USER_FAILED',
  EDIT_USER: 'EDIT_USER',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILED: 'EDIT_USER_FAILED',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILED: 'DELETE_USER_FAILED',
  USER_STATUS_CHANGE: 'USER_STATUS_CHANGE',
  USER_STATUS_CHANGE_SUCCESS: 'USER_STATUS_CHANGE_SUCCESS',
  USER_STATUS_CHANGE_FAILED: 'USER_STATUS_CHANGE_FAILED',
  GET_ROLES: 'GET_ROLES',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILED: 'GET_ROLES_FAILED',
  GET_MODULES: 'GET_MODULES',
  GET_MODULES_SUCCESS: 'GET_MODULES_SUCCESS',
  GET_MODULES_FAILED: 'GET_MODULES_FAILED',
  OPEN_USER_FORM: 'OPEN_USER_FORM',

  getUserData: (offset, limit, search) => ({
    type: actions.GET_USER_DATA,
    payload: { offset, limit, search},
  }),
  getUserDataSuccess: (data, totalData) => ({
    type: actions.GET_USER_DATA_SUCCESS,
    payload: { data, totalData },
  }),
  getUserDataFailed: (error) => ({
    type: actions.GET_USER_DATA_FAILED,
    payload: { error },
  }),

  addUser: (data) => ({
    type: actions.ADD_USER,
    payload: { data },
  }),
  addUserSuccess: (message) => ({
    type: actions.ADD_USER_SUCCESS,
    payload: { message },
  }),
  addUserFailed: (error) => ({
    type: actions.ADD_USER_FAILED,
    payload: { error },
  }),

  
  editUser: (userId, data) => ({
    type: actions.EDIT_USER,
    payload: { userId, data },
  }),
  editUserSuccess: (message) => ({
    type: actions.EDIT_USER_SUCCESS,
    payload: { message },
  }),
  editUserFailed: (error) => ({
    type: actions.EDIT_USER_FAILED,
    payload: { error },
  }),

  deleteUser: (id) => ({
    type: actions.DELETE_USER,
    payload: { id },
  }),
  deleteUserSuccess: (message) => ({
    type: actions.DELETE_USER_SUCCESS,
    payload: { message },
  }),
  deleteUserFailed: (error) => ({
    type: actions.DELETE_USER_FAILED,
    payload: { error },
  }),
  userStatusChange: (data) => ({
    type: actions.USER_STATUS_CHANGE,
    payload: { data },
  }),
  userStatusChangeSuccess: (message) => ({
    type: actions.USER_STATUS_CHANGE_SUCCESS,
    payload: { message },
  }),
  userStatusChangeFailed: (error) => ({
    type: actions.USER_STATUS_CHANGE_FAILED,
    payload: { error },
  }),


  getRoles: () => ({
    type: actions.GET_ROLES,
  }),
  getRolesSuccess: (rolesData) => ({
    type: actions.GET_ROLES_SUCCESS,
    rolesData
  }),
  getRolesFailed: (error) => ({
    type: actions.GET_ROLES_FAILED,
    payload: { error },
  }),


  getModules: () => ({
    type: actions.GET_MODULES,
  }),
  getModulesSuccess: (modulesData) => ({
    type: actions.GET_MODULES_SUCCESS,
    modulesData,
  }),
  getModulesFailed: (error) => ({
    type: actions.GET_MODULES_FAILED,
    payload: { error },
  }),

  openForm: () => ({
    type: actions.OPEN_USER_FORM,
  }),
  

  userReset: () => ({
    type: actions.USER_RESET,
  }),
  userResetMessage: () => ({
    type: actions.USER_RESET_MESSAGE,
  }),
};

export default actions;
