const actions = {
  GET_COMPLAINTS_DATA: 'GET_COMPLAINTS_DATA',
  GET_COMPLAINTS_DATA_SUCCESS: 'GET_COMPLAINTS_DATA_SUCCESS',
  GET_COMPLAINTS_DATA_FAILED: 'GET_COMPLAINTS_DATA_FAILED',


  getComplaintsData: (offset, limit, search, fromDate, toDate,agentId) => ({
    type: actions.GET_COMPLAINTS_DATA,
    payload: { offset, limit, search, fromDate, toDate,agentId },
  }),
  getComplaintsDataSuccess: (data, totalData) => ({
    type: actions.GET_COMPLAINTS_DATA_SUCCESS,
    payload: { data, totalData },
  }),
  getComplaintsDataFailed: (error) => ({
    type: actions.GET_COMPLAINTS_DATA_FAILED,
    payload: { error },
  }),

};

export default actions;
