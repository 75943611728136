import actions from './actions';

const initState = {
  loading: false,
  message: null,
  buttonLoading: false,
  error: null,
  transactionData: {},
  chartData:{},
  totalData: null,
  show: false
};


export default function authReducer(state = initState, action) {
  switch (action.type) {

    case actions.GET_TRANSACTIONS_DATA:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TRANSACTIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.payload.data,
        totalData: action.payload.totalData
      };
    case actions.GET_TRANSACTIONS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

      case actions.GET_CHART_DATA:
        return {
          ...state,
          loading: true,
        };
      case actions.GET_CHART_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          chartData: action.payload.data,  
        };
      case actions.GET_CHART_DATA_FAILED:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
  
    default:
      return state;
  }

}
