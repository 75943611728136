import React from 'react'

function CustomerParameters() {
    return (
        <div className="mainContain">
            <h1 className="transaction">Customer Params</h1>
            <div className='d-flex m-3 card-cp'>
                <div className='col-3 inner-cp'>Param Name</div>
                <div className='col-3 inner-cp'>Optional</div>
                <div className='col-3 inner-cp'>Data Type</div>
                <div className='col-3 inner-cp'>Max Length</div>
                <div className='col-3 inner-cp'>Min Length</div>
                <div className='col-3 inner-cp'>Regex</div>
                <div className='col-3 inner-cp'>Values</div>
                <div className='col-3 inner-cp'>Visibility</div>
            </div>
        </div>
    )
}

export default CustomerParameters
