import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import CryptoJS from 'crypto-js';
import Overview from "../../Pages/Overview/Overview"
import Transactions from "../../Pages/Transactions/Transactions"
import Simpl from "../../Pages/SIMPL/Simpl"
import Refund from "../../Pages/Transactions/Refund"
import Unauthorized from "../../Pages/Unauthorized/Unauthorized";
import User from "../../Pages/Users/User";
import CustomerParameters from "../../Pages/Customer Parameters/CustomerParameters";
// import SettlementReconciliation from "../../Pages/SettlementReconciliation/settlementReconciliation";
import SettlementReconciliation from "../../Pages/SettlementReconciliation/NewSettlementReconciliation";
import Complaints from "../../Pages/Complaints/Complaints";
import Biller from "../../Pages/Biller Performance/Biller";
import Communicationchannel from "../../Pages/Communication Channel/communication";
import Mails from "../../Pages/Mails";
import RepcoIntegration from "../../Pages/Repco Integration";

function AppRouters() {
    const permissionsLocalStorage = localStorage.getItem('module_access');
    const decryptedData = permissionsLocalStorage 
        ? CryptoJS.AES.decrypt(permissionsLocalStorage, process.env.REACT_APP_ENCRYPTED_KEY).toString(CryptoJS.enc.Utf8) 
        : null;
    const permissionsData = decryptedData ? JSON.parse(decryptedData) : {};
    const isSuperAdmin = permissionsData.permissions === true;
    const permissions = permissionsData.permissions || [];

    return (
        <div className="AppPageConntent">
        <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="/transactions" element={checkPermission(isSuperAdmin, permissions, "transactions") ? <Transactions /> : <Navigate to="/unauthorized" />} />
            <Route path="/refund" element={checkPermission(isSuperAdmin, permissions, "refund") ? <Refund /> : <Navigate to="/unauthorized" />} />
            <Route path="/simpl" element={checkPermission(isSuperAdmin, permissions, "simpl") ? <Simpl /> : <Navigate to="/unauthorized" />} />
            <Route path="/user" element={checkPermission(isSuperAdmin, permissions, "users") ? <User /> : <Navigate to="/unauthorized" />} />
            <Route path="/customer-parameters" element={checkPermission(isSuperAdmin, permissions, "customer-parameters") ? <CustomerParameters /> : <Navigate to="/unauthorized" />} />
            <Route path="/settlement" element={checkPermission(isSuperAdmin, permissions, "settlements") ? <SettlementReconciliation /> : <Navigate to="/unauthorized" />} />
            <Route path="/complaints" element={checkPermission(isSuperAdmin, permissions, "complaints") ? <Complaints /> : <Navigate to="/unauthorized" />} />
            <Route path="/biller-performance" element={checkPermission(isSuperAdmin, permissions, "Biller Performance") ? <Biller /> : <Navigate to="/unauthorized" />} />
            <Route path="/communication-channel" element={checkPermission(isSuperAdmin, permissions, "Communication Channel") ? <Communicationchannel /> : <Navigate to="/unauthorized" />} />
            <Route path="/mails" element={checkPermission(isSuperAdmin, permissions, "mails") ? <Mails /> : <Navigate to="/unauthorized" />} />
            {/* <Route path="/repco-integration" element={checkPermission(isSuperAdmin, permissions, "repco integration") ? <RepcoIntegration /> : <Navigate to="/unauthorized" />} /> */}
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<Unauthorized />} />
        </Routes>
        </div>
    );
}

function checkPermission(isSuperAdmin, permissions, requiredPermission) {
    return isSuperAdmin ? true : permissions.some(permission => permission.module.toLowerCase() === requiredPermission.toLowerCase() && permission.read);
}

export default AppRouters;
