import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../Comman/Table";
import "./transaction.scss";
import moment from "moment";
import jsonData from "./transaction.json";
import { DatePicker, Input, Form } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

const { RangePicker } = DatePicker;

function Refund() {
  const [search, setSearch] = useState("");
  const [filterText, setfilterText] = useState();
  const [loading, setLoading] = useState();
  const [selectedDate, setSelectedDate] = useState(null);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const navigate = useNavigate();

//for transaction pages
  const HandleChange = () => {
    navigate('/transactions');
  };

  // console.log("JsonData", JsonData)
  console.log(jsonData);

  const columns = [
    { displayName: "Name", key: "name" },
    { displayName: "Price", key: "price" },
    { displayName: "EMAIL", key: "email" },
    { displayName: "Payment Mode", key: "Payment_Mode" },
    { displayName: "CREATE DATE", key: "date" },
    { displayName: "STATUS", key: "status" },
    { displayName: "ACTIONS", key: "action" },
  ];
  const filteredItems =
    jsonData?.dealerResult &&
    jsonData?.dealerResult.length > 0 &&
    jsonData?.dealerResult.filter(
      (item) => JSON.stringify(item)
      // .toLowerCase()
      // .indexOf(filterText.toLowerCase()) !== -1
    );
  var data =
    filteredItems &&
    filteredItems.length > 0 &&
    filteredItems.map((dealer, key) => {
      return {
        name: dealer.name,
        email: dealer.email,
        Payment_Mode: dealer.Payment_Mode,
        price: dealer.price,
        createDate:
          dealer.date !== null
            ? moment(new Date(dealer.date)).format("YYYY-MM-DD HH:mm:ss")
            : "",
      };
    });

  return (
    <div className="mainContain">
      <h1 className="transaction">Transactions</h1>
      <div className="d-flex gap-3">
        <p  onClick={HandleChange} className="suntaginactive">Transaction</p>
        <p className="suntag">Refund</p>
      </div>
      <div className="datesearchFi">
      <div className="d-flex">
          <select className="transactioSelct">
            <option>Transaction ID</option>
            <option>First Value</option>
            <option>Ref ID</option>
            <option>PRICE ID</option>
            <option>Revgg ID</option>
          </select>
          <input
            type="text"
            placeholder="Search  by Transaction ID, Email ID"
            className="inputSearch"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
     </div>
          <div className="inputfatef bg-white">
            <div style={{borderRight:"1px solid rgba(141, 141, 141, 1)"}}>
              <span>From (mm/dd/yyyy)</span>
              <DatePicker
                value={fromDate}
                style={{width:"100%" , border:"none"}}
                onChange={handleFromDateChange}
                suffixIcon={<CalendarOutlined style={{ color: "rgba(22, 22, 22, 0.25)" }} />}
              />
            </div>
            <div>
              <span>To (mm/dd/yyyy)</span>
              <DatePicker
                value={toDate}
                style={{width:"100%" , border:"none"}}
                onChange={handleToDateChange}
                suffixIcon={<CalendarOutlined style={{ color: "rgba(22, 22, 22, 0.25)" }} />}
              />
            </div>
          </div>
        <div>
          <select className="transactioSelct">
            <option>Filter</option>
          </select>
        </div>
      </div>
      <div className="cardClass">
        <div className="cardpayment">
          <div>Total Payment</div>
          <span>₹ 34,26,023</span>
        </div>
        <div className="cardpayment">
          <div>Total Payment</div>
          <span>₹ 34,26,023</span>
        </div>
        <div className="cardpayment">
          <div>Total Payment</div>
          <span>₹ 34,26,023</span>
        </div>
      </div>
      <Table
        columns={columns}
        loading={loading}
        data={data}
        pagination={true}
        totalPage={jsonData.totalData}
      />
    </div>
  );
}

export default Refund;
