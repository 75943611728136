import "./index.css";
import Header from "./layouts/Header/Header";
import Sidebar from "./layouts/Sidebar/Sidebar";
import LoginRoutes from "./layouts/Routers/loginRoutes";
import AppRouters from "./layouts/Routers/Routers";
import Cookies from "js-cookie";


function App() {
  const token = Cookies.get("biller_access");
  return (
    <div className="App">
      {token ? (      
        <>
          <Header />
          {/* <SendBox /> */}
          <div className="partDashboard">
            <Sidebar />
            <AppRouters />
          </div>
        </>
      ) : (
        <LoginRoutes />
       )}
    </div>
  );
}
export default App;
